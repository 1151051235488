import React, { createContext, useReducer } from 'react';

import PropTypes from 'prop-types';

const reducer = (state, action) => {
  switch (action.type) {
    case 'claimTagOwnership':
      return {
        ...state,
        isTagOwner: action.payload.isTagOwner,
        isTagHasOwner: action.payload.isTagHasOwner,
        isClaming: action.payload.isClaming
      };
    case 'tagSearch':
      // not will always have all these properties - even if tag not found - from the code which calls this dispatch
      return {
        ...state,
        eid: action.payload.eid,
        found: action.payload.found,
        history: action.payload.history,
        isadmin: action.payload.isadmin,
        isTagHasOwner: action.payload.isTagHasOwner,
        isTagOwner: action.payload.isTagOwner,
        locations: action.payload.locations,
        properties: action.payload.properties,
        search: action.payload.search,
        tagid: action.payload.tagid,
        taglocationid: null,
        vid: action.payload.vid,
        isClaming: action.payload.isClaming
      };
    case 'tagSearchChangeLanguage':
          // removed the taglocationid: null,

          // only changed
          // properties
          // history
          return {
            ...state,
            eid: action.payload.eid,
            found: action.payload.found,
            history: action.payload.history,
            locations: action.payload.locations,
            properties: action.payload.properties,
            search: action.payload.search,
            tagid: action.payload.tagid,
            vid: action.payload.vid,
            isTagOwner: action.payload.isTagOwner,
            isTagHasOwner: action.payload.isTagHasOwner,
            isClaming: action.payload.isClaming
          };
    case 'updateTagHistory':
        return {
          ...state,
          history: action.payload.history,
          properties: action.payload.properties,
        };
    case 'updateTagLocation':
        return {
          ...state,
          locations: action.payload.locations,
          taglocationid: action.payload.taglocationid,
        };
    default:
      return state;
  }
};

const initialState = {
  eid: null,
  found: null,
  history: null,
  isadmin: null,
  istaghasowner: null,
  isTagOwner: null,
  locations: null,
  properties: null,
  search: '',
  tagid: null,
  taglocationid: null,
  vid: null,
};

const TagContext = createContext(initialState);

export function TagProvider(props) {
  const [tagState, tagDispatch] = useReducer(reducer, initialState);
  const { children } = props;
  return (
    <TagContext.Provider value={{ tagState, tagDispatch }}>
      {children}
    </TagContext.Provider>
  );
}

TagProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired
};

export default TagContext;
