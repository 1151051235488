import React from 'react';

import {
  Image,
  Menu,
  Text,
} from 'grommet';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import MaterialIcon from 'material-icons-react';
import i18nParent from '../../i18n';

const Header = (props) => {
  const { handleSignout } = props;

  const { t } = useTranslation('translations', { i18nParent });

  return (
    <header>
      <a href="/" title={t('Administration')} target="_blank" rel="noopener noreferrer">
        <Image
          src="favicon.ico"
          style={{ float: 'left', marginRight: '1em', width: '30px' }}
        />
      </a>

      <Text className="text" size="large">
        {t('LIRS.io')}
      </Text>

      <Menu
        icon={<MaterialIcon icon="menu" color="rgb(0, 79, 151)" />}
        items={[
          {
            icon: <MaterialIcon icon="manage_accounts" color="rgb(0, 79, 151)" />,
            label: <Text style={{ width: '100px', 'padding-left': '10px' }}><a href="https://admin.lirs.io" title={t('Administration')} style={{ 'text-decoration': 'none' }} target="_blank" rel="noopener noreferrer">{t('Admin')}</a></Text>,
          },
          {
            icon: <MaterialIcon icon="logout" color="rgb(0, 79, 151)" />,
            label: <Text style={{ width: '100px', 'padding-left': '10px' }}>{t('Logout')}</Text>,
            onClick: () => { handleSignout(); }
          },
        ]}
        style={{ border: '2px solid rgb(0, 79, 151)', float: 'right', marginTop: '-0.75em' }}
      />
    </header>
  );
};

Header.propTypes = {
  handleSignout: PropTypes.func.isRequired,
};

export default Header;
