import React from 'react';
import {
  Button,
  Form,
  FormField,
  Select,
  Text,
  TextInput,
} from 'grommet';
import API from '@aws-amplify/api';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import MaterialIcon from 'material-icons-react';
import i18nParent from '../../i18n';
// import PropTypes from 'prop-types';
import LocationContext from '../../global/LocationContext';
import TagContext from '../../global/TagContext';
import UseMyCurrentLocation from './UseMyCurrentLocation';
import Spinner from './Spinner';

async function nearbyLocationsApi(coords, language) {
  const apiName = 'lirs';
  const path = '/nearby-locations';

  const myInit = {
    headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
    body: {
      language: language.substr(0, 2),
      latitude: coords.latitude,
      longitude: coords.longitude,
      username: `${(await Auth.currentSession()).getIdToken().payload['cognito:username']}`,
    },
  };

  return API.post(apiName, path, myInit);
}

async function createLocationPointApi(coords, name) {
  const apiName = 'lirs';
  const path = '/create-location-point';

  const myInit = {
    headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
    body: {
      latitude: coords.latitude,
      longitude: coords.longitude,
      name,
      username: `${(await Auth.currentSession()).getIdToken().payload['cognito:username']}`,
    },
  };

  return API.post(apiName, path, myInit);
}

const LocationSummary = () => {
  const { t, i18n } = useTranslation('translations', { i18nParent });
  const { language } = i18n;

  const { tagState } = React.useContext(TagContext);
  const { taglocationid } = tagState;

  const { locationState, locationDispatch } = React.useContext(LocationContext);
  const {
    coords,
    nearbylocationid,
    nearbylocationname,
    nearbylocations
  } = locationState;

  const locationOptions = nearbylocations === null ? [] : nearbylocations;

  const [coordsInProgress, setCoordsInProgress] = React.useState(false);
  const [nearbyLocationInProgress, setNearbyLocationInProgress] = React.useState(false);
  const [addNewLocation, setAddNewLocation] = React.useState(false);
  const [addNewLocationInProgress, setAddNewLocationInProgress] = React.useState(false);
  const mess = 'Getting your location ...';

  // get coords - if not already set in location context
  if (coords == null) {
    // this prevents the geolocation being called twice before the promise completed
    if (!coordsInProgress) {
      // no coords

      UseMyCurrentLocation().then((coords2) => {
        // update location context
        locationDispatch({
          type: 'setCoords',
          payload: { coords: coords2 },
        });

        setCoordsInProgress(false);
      });
      setCoordsInProgress(true);
    }
    // was <Text alignSelf="center" textAlign="center">{t('Getting your location ...')}</Text>
    // now using spinner message
    const dots = '.';
    return (
      <div>
        <Spinner spinnermessage={mess} dots={dots} />
        <br />
      </div>
    );
  }

  // find nearby locations - if not already set in location context
  if (nearbylocations == null) {
    // this prevents the api being called twice before the promise  hascompleted
    if (!nearbyLocationInProgress) {
      // find nearby locations

      nearbyLocationsApi(coords, language).then((response) => {
        const options = response.items;

        const templocationOptions = [];
        options.forEach((option) => {
          templocationOptions.push({ label: option.name, value: option.id });
        });

        const payload = {
          nearbylocationid: templocationOptions[0].value,
          nearbylocationname: templocationOptions[0].label,
          nearbylocations: templocationOptions,
        };

        // update location context
        // was         <Text alignSelf="center" textAlign="center">{t('Getting your location ...')}</Text>
        // now using spinner message
        locationDispatch({
          type: 'setNearbyLocations',
          payload,
        });

        setNearbyLocationInProgress(false);
      });
      setNearbyLocationInProgress(true);
    }

    const dots = '...';
    return (
      <div>
        <Spinner spinnermessage={mess} dots={dots} />
        <br />
      </div>
    );
  }

  if (addNewLocation) {
    // this prevents the api being called twice before the promise  hascompleted
    if (!addNewLocationInProgress) {
      return (
        <div>

          <Form onSubmit={
              ({ value }) => {
                const { name } = value;

                if (name === '') {
                  // eslint-disable-next-line no-alert
                  alert(t('Error: Please enter a name for the new location.'));
                  return false;
                }

                createLocationPointApi(coords, name).then((response) => {
                  const payload = {
                    id: response.id,
                    name,
                  };

                  // update location context
                  locationDispatch({
                    type: 'addNearbyLocation',
                    payload,
                  });

                  setAddNewLocationInProgress(false);
                });

                setAddNewLocation(false);
                setAddNewLocationInProgress(true);

                return false;
              }
            }
          >

            <FormField
              component={TextInput}
              label={t('New location name')}
              name="name"
              style={{ margin: '0px auto', maxWidth: '200px' }}
            />

            <Button
              alignSelf="center"
              className="button"
              icon={<MaterialIcon icon="cancel" color="white" />}
              label={t('Cancel')}
              onClick={() => { setAddNewLocation(false); }}
              primary
              style={{ margin: '0px auto' }}
              type="submit"
              value="submit"

            />

            <Button
              alignSelf="center"
              className="button"
              icon={<MaterialIcon icon="save" color="white" />}
              label={t('Save')}
              primary
              style={{ margin: '0px auto' }}
              type="submit"
              value="submit"

            />

          </Form>

        </div>
      );
    }

    return (
      <div>
        <Text alignSelf="center" textAlign="center">{t('Adding new location ...')}</Text>
        <br />
      </div>
    );
  }

  return (
    <div>
      <Text>
        {t('Location')}
      </Text>
      {' '}
      <Button
        className="button"
        icon={<MaterialIcon className="material-icons md-18 md-dark" icon="add_box" color="white" />}
        onClick={() => { setAddNewLocation(true); }}
        style={{ borderRadius: '5px', padding: '3px' }}
      />
      <br />
      <Select
        disabled={taglocationid !== null}
        emptySearchMessage={t('No matches found')}
        labelKey="label"
        multiple={false}
        onChange={
          (option) => {
            locationDispatch({
              type: 'setLocation',
              payload: option.value,
            });
          }
        }
        options={locationOptions}
        placeholder={t('Select a location')}
        size="small"
        style={{ width: '100% !important' }}
        value={{ label: nearbylocationname, value: nearbylocationid }}
        valueKey="value"
      />
    </div>
  );
};

export default LocationSummary;
