import React from 'react';

import {
  Button,
  Heading,
  Form,
  FormField,
  Select,
  TextInput
} from 'grommet';

import MaterialIcon from 'material-icons-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18nParent from '../../i18n';

import RecordTagHistoryApi from './RecordTagHistoryApi';
import KeyPairsToOptions from './KeyPairsToOptions';
import FormLookupListsApi from './FormLookupListsApi';
import ActiveOptions from './ActiveOptions';

import TagContext from '../../global/TagContext';
import Spinner from '../Common/Spinner';

const Vaccination = (props) => {
  const { resetForm } = props;

  const { t, i18n } = useTranslation('translations', { i18nParent });
  const { language } = i18n;

  const { tagState, tagDispatch } = React.useContext(TagContext);
  const { tagid, taglocationid } = tagState;

  const [lookupLists, setLookupLists] = React.useState({});
  const mess = 'Loading';

  // form inputs
  const [vaccineValue, setVaccineValue] = React.useState('');
  const [batchNumberValue, setBatchNumberValue] = React.useState('');
  const [doseQuantityValue, setDoseQuantityValue] = React.useState(2);
  const [doseQuantityUnitValue, setDoseQuantityUnitValue] = React.useState('');

  // get lookups
  if (Object.entries(lookupLists).length === 0) {
    FormLookupListsApi('vaccination', language).then((response) => {
      setLookupLists(response);
    });

    return (<Spinner spinnermessage={mess} />);
  }

  if (vaccineValue === '') {
    if (Object.prototype.hasOwnProperty.call(lookupLists.defaults, 'vaccine')) {
      setVaccineValue(lookupLists.defaults.vaccine);
    }
  }

  if (doseQuantityUnitValue === '') {
    if (Object.prototype.hasOwnProperty.call(lookupLists.defaults, 'dose_quantity_unit')) {
      setDoseQuantityUnitValue(lookupLists.defaults.dose_quantity_unit);
    }
  }

  return (
    <div style={{ margin: '0px auto', textAlign: 'center' }}>
      <Heading margin="xsmall" level="4" size="xsmall">{t('Vaccination')}</Heading>

      <Form onSubmit={
          ({ value }) => {
            let value2 = value;

            // convert lookup lists from labels to values
            if ('vaccine' in value2) {
              value2.vaccine = parseInt(value2.vaccine.value, 10);
            }
            if ('dose_quantity_unit' in value2) {
              value2.dose_quantity_unit = parseInt(value2.dose_quantity_unit.value, 10);
            }

            value2 = {
              vaccination: value
            };

            // record tag history
            RecordTagHistoryApi(language, tagid, taglocationid, value2).then((response) => {
              // redoing the whole history here because
              // we need to translate the form and form fields
              const payload = {
                history: response.history,
                properties: response.properties,
              };

              // update tag context
              tagDispatch({
                type: 'updateTagHistory',
                payload,
              });

              resetForm();
            });
          }
        }
      >
        <FormField
          label={t('Vaccine')}
        >
          <Select
            alignSelf="center"
            labelKey="label"
            margin="0px center"
            multiple={false}
            name="vaccine"
            onChange={
              ({ option }) => {
                setVaccineValue({ label: lookupLists.keypairs.vaccine[option.value], value: option.value });
              }
            }
            options={KeyPairsToOptions(ActiveOptions(lookupLists.keypairs.vaccine, lookupLists.activekeys.vaccine))}
            required
            value={vaccineValue}
            valueKey="value"
          />
        </FormField>

        <FormField
          label={t('Batch number')}
        >
          <TextInput
            name="batch_number"
            onChange={(event) => setBatchNumberValue(event.target.value)}
            required
            value={batchNumberValue}
          />
        </FormField>

        <FormField
          label={t('Dose quantity')}
        >
          <TextInput
            name="dose_quantity"
            onChange={(event) => setDoseQuantityValue(event.target.value)}
            required
            value={doseQuantityValue}
          />
        </FormField>

        <FormField
          label={t('Dose quantity unit')}
        >
          <Select
            alignSelf="center"
            labelKey="label"
            margin="0px center"
            multiple={false}
            name="dose_quantity_unit"
            onChange={
              ({ option }) => {
                setDoseQuantityUnitValue(
                  {
                    label: lookupLists.keypairs.dose_quantity_unit[option.value],
                    value: option.value
                  }
                );
              }
            }
            options={
              KeyPairsToOptions(
                ActiveOptions(lookupLists.keypairs.dose_quantity_unit, lookupLists.activekeys.dose_quantity_unit)
              )
            }
            required
            value={doseQuantityUnitValue}
            valueKey="value"
          />
        </FormField>

        <br />

        <Button
          alignSelf="center"
          className="button"
          icon={<MaterialIcon icon="save" color="white" />}
          label={t('Save')}
          primary
          type="submit"
        />
      </Form>

      <br />

    </div>
  );
};

Vaccination.propTypes = {
  resetForm: PropTypes.func.isRequired
};

export default Vaccination;
