import React from 'react';
import {
  Button,
  Heading,
  Form,
  FormField,
  TextInput
} from 'grommet';
import PropTypes from 'prop-types';
import MaterialIcon from 'material-icons-react';
import { useTranslation } from 'react-i18next';
import i18nParent from '../../i18n';
import RecordTagHistoryApi from './RecordTagHistoryApi';

import TagContext from '../../global/TagContext';

const Weight = (props) => {
  const { resetForm } = props;

  const { t, i18n } = useTranslation('translations', { i18nParent });
  const { language } = i18n;

  const { tagState, tagDispatch } = React.useContext(TagContext);
  const { tagid, taglocationid } = tagState;

  // form inputs
  const [weightValue, setWeightValue] = React.useState('');

  return (
    <div style={{ margin: '0px auto', textAlign: 'center' }}>
      <Heading margin="xsmall" level="4" size="xsmall">{t('Weight')}</Heading>

      <Form onSubmit={
          ({ value }) => {
            const value2 = {
              weight: value
            };

            // record tag history
            RecordTagHistoryApi(language, tagid, taglocationid, value2).then((response) => {
              // redoing the whole history here because
              // we need to translate the form and form fields
              const payload = {
                history: response.history,
                properties: response.properties,
              };

              // update tag context
              tagDispatch({
                type: 'updateTagHistory',
                payload,
              });

              resetForm();
            });
          }
        }
      >
        <FormField
          label={`${t('Weight')} (${t('kilograms')})`}
        >
          <TextInput
            name="weight"
            onChange={(event) => setWeightValue(event.target.value)}
            required
            value={weightValue}
          />
        </FormField>

        <br />

        <Button
          alignSelf="center"
          className="button"
          icon={<MaterialIcon icon="save" color="white" />}
          label={t('Save')}
          primary
          type="submit"
        />
      </Form>

      <br />

    </div>
  );
};

Weight.propTypes = {
  resetForm: PropTypes.func.isRequired
};

export default Weight;
