import React from 'react';

// amplify

import { Amplify, I18n as I18nAmplify } from 'aws-amplify';

// https://docs.amplify.aws/ui/auth/authenticator/q/framework/react
// translations
// https://github.com/aws-amplify/amplify-js/blob/master/packages/amplify-ui-components/src/common/Translations.ts
// form field types
// https://github.com/aws-amplify/amplify-js/blob/main/packages/amplify-ui-components/src/components/amplify-auth-fields/amplify-auth-fields-interface.ts#L3

import {
  AmplifyAuthenticator,
  AmplifySignIn,
} from '@aws-amplify/ui-react';
import API from '@aws-amplify/api';

// import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import { BrowserRouter as Router } from 'react-router-dom';
import {
  Button,
  Grommet,
} from 'grommet';

import { useTranslation } from 'react-i18next';

import MaterialIcon from 'material-icons-react';
import amplifyConfig from './global/aws-exports';

import i18nParent from './i18n';

// ui
import 'radium'; // required for media queries in css

// project files
import UserContext from './global/UserContext';
import UserIdContext from './global/UserIdContext';
import useAmplifyAuth from './components/Auth';
import Main from './components/Main';
import Language from './components/Common/Language';
import Footer from './components/Common/Footer';
import Header from './components/Common/Header';
import HeaderLogin from './components/Common/HeaderLogin';
import Spinner from './components/Common/Spinner';
import TagInfo2 from './components/Tag/TagInfo2';
import TagVaccinationHistory from './components/Tag/TagVaccinationHistory';

// import ChangeLanguage from './components/Common/ChangeLanguage';

// project files - ui
import grommetThemeConfig from './global/Grommet';
import './css/App.css';
import './css/Amplify.css';

import translationEn from './locales/en/translation.json';
import translationKm from './locales/km/translation.json';
import translationLo from './locales/lo/translation.json';

Amplify.configure(amplifyConfig);

async function tagSearchPublicApi(search, language) {
  const apiName = 'lirs';
  const path = '/tag-search-public';

  const myInit = {
    body: {
      language: language.substr(0, 2),
      search
    },
  };

  return API.post(apiName, path, myInit);
}

const App = () => {
  const { t, i18n } = useTranslation('translations', { i18nParent });
  const { language } = i18n;

  const languageToAreaCode = {
    en: '+61',
    km: '+855',
    lo: '+856',
  };

  const AREA_CODE_SETTINGS = {
    '+61': `${t('Australia')} (+61)`,
    '+880': `${t('Bangladesh')} (+880)`,
    '+673': `${t('Brunei')} (+673)`,
    '+855': `${t('Cambodia')} (+855)`,
    '+86': `${t('China')} (+86)`,
    '+852': `${t('Hong Kong')} (+852)`,
    '+91': `${t('India')} (+91)`,
    '+62': `${t('Indonesia')} (+62)`,
    '+856': `${t('Laos')} (+856)`,
    '+853': `${t('Macau')} (+853)`,
    '+60': `${t('Malaysia')} (+60)`,
    '+95': `${t('Myanmar')} (+95)`,
    '+65': `${t('Singapore')} (+65)`,
    '+66': `${t('Thailand')} (+66)`,
    '+670': `${t('Timor-Leste')} (+670)`,
    '+84': `${t('Vietnam')} (+84)`,
  };

  // tag search for public tag lookup
  // found - response from api
  // api - call api once only
  const [tagStatePublic, setTagStatePublic] = React.useState({ found: null, api: false });

  // if user switches language whilst viewing tag information then we want to hide all tag information
  //  e.g. make not found so that they have to search again
  // otherwise tag context will continue to be other language
  // and lookup lists in forms
  const [tagLanguage, setTagLanguage] = React.useState(language);

   // amplifysignin
  const [amplifySignInAreaCode, setAmplifySignInAreaCode] = React.useState(
    languageToAreaCode[language.substring(0, 2)]
  );
  const [amplifySignInPhoneNumber, setAmplifySignInPhoneNumber] = React.useState('');

  const TagFoundPublic = () => {
    if (tagStatePublic.found) {
      return (
        <div>
          <h2 style={{ textAlign: 'center' }}>
            {t('Tag found')}
          </h2>

          <br />
          <TagInfo2
            eid={tagStatePublic.eid}
            locations={tagStatePublic.locations}
            properties={tagStatePublic.properties}
            vid={tagStatePublic.vid}
          />
          <h3 style={{ textAlign: 'center' }}>
            {t('Vaccination history')}
          </h3>
          <TagVaccinationHistory history={tagStatePublic.history} />

          <hr />

          <br />

          <a href="/" style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              className="button"
              icon={<MaterialIcon icon="login" color="white" />}
              label={t('Login')}
              primary
              size="large"
              style={{ margin: '0px auto', textAlign: 'center' }}
              type="button"
            />
          </a>

          <br />

        </div>
      );
    }

    return (
      <h2 style={{ textAlign: 'center' }}>
        {t('Not signed in')}
      </h2>
    );
  };

  switch (i18n.language) {
    case 'en':
      I18nAmplify.putVocabularies({ en: translationEn.translations });
      break;
    case 'km':
      I18nAmplify.putVocabularies({ km: translationKm.translations });
      break;
    case 'lo':
      I18nAmplify.putVocabularies({ lo: translationLo.translations });
      break;
    default:
      I18nAmplify.putVocabularies({ en: translationEn.translations });
  }

  if (i18n.language !== I18nAmplify.language) {
    I18nAmplify.setLanguage(i18n.language);
  }

  const {
    state: { user },
    handleSignout,
  } = useAmplifyAuth();
  const userId = user ? user.username : null;

  let tagLookup = '';
  if (window.location.pathname !== '/') {
    tagLookup = window.location.pathname.replace('/', '');
  }

  if (!user) {
    if (tagLookup !== '') {
      // user is not logged in and scanning a tag

      if (tagLanguage !== language) {
        setTagLanguage(language);
        setTagStatePublic({ found: null, api: false });
        return '';
      }

      if (tagStatePublic.found === null) {
        if (tagStatePublic.api === false) {
          // tag search public
          tagSearchPublicApi(tagLookup, language).then(
            (response) => {
              const payload = {
                eid: response.eid,
                found: response.found,
                history: response.history,
                locations: response.locations,
                properties: response.properties,
                search: tagLookup,
                tagid: response.tagid,
                vid: response.vid,
              };

              setTagStatePublic(payload);
            }
          );

          setTagStatePublic({ found: null, api: true });
        }

        return (
          <div>
            <HeaderLogin />

            <div style={{
                backgroundColor: 'white',
                boxShadow: 'rgba(0, 0, 0, 0.15) 1px 1px 4px 0px',
                margin: '0px auto',
                width: '380px'
              }}
            >
              <br />
              <Spinner />
            </div>

            <br />

            <Language />

            <br />
            <br />

            <Footer />

            <br />
            <br />
          </div>
        );
      }

      // response
      return (
        <Grommet theme={grommetThemeConfig}>
          <HeaderLogin />

          <div style={{
              backgroundColor: 'white',
              boxShadow: 'rgba(0, 0, 0, 0.15) 1px 1px 4px 0px',
              margin: '0px auto',
              padding: '1em',
              width: '380px'
            }}
          >
            {TagFoundPublic()}
          </div>

          <br />

          <Language />

          <br />
          <br />

          <Footer />

          <br />
          <br />
        </Grommet>
      );
    }

    // user is not logged in and not looking up a tag
    return (
      <div>
        <HeaderLogin />

        <br />

        <AmplifyAuthenticator
          id="amplify_authenticator"
          style={{
            alignItems: 'flex-start',
            display: 'flex',
            justifyContent: 'center',
          }}
          usernameAlias="phone_number"
        >

          <AmplifySignIn
            formFields={[
              {
                dialCode: amplifySignInAreaCode,
                handleInputChange: (event) => {
                  // no parameter reassignment
                  const event2 = event;

                  if (event.target.tagName === 'SELECT') {
                    setAmplifySignInAreaCode(event.target.value);
                  } else if (event.target.tagName === 'INPUT') {
                    // remove leading zeros from phone number
                    event2.target.value = event2.target.value.replace(/^0/, '');
                    // remove spaces from phone number
                    event2.target.value = event2.target.value.replace(' ', '');
                    // remove spaces from phone number - required to do a second time
                    setTimeout(event2.target.value = event2.target.value.replace(' ', ''), 100);

                    setAmplifySignInPhoneNumber(event2.target.value.replace(' ', ''));
                  }
                },
                inputProps: {
                  autofocus: true,
                  onfocus: (objFocusEvent) => {
                    // amplify-country-dial-code
                    const objCountryDialCodeSelector = objFocusEvent.target.parentNode.parentNode.children[0];
                    // eslint-disable-next-line max-len
                    const objCountryDialCodeSelectBoxSelector = objCountryDialCodeSelector.shadowRoot.children[0].shadowRoot.children[0];

                    setTimeout(() => {
                      objCountryDialCodeSelectBoxSelector.innerHTML = '+61';

                      for (let index = 0; index < Object.keys(AREA_CODE_SETTINGS).length; index += 1) {
                        const option = document.createElement('option');
                        option.label = Object.values(AREA_CODE_SETTINGS)[index];
                        option.value = Object.keys(AREA_CODE_SETTINGS)[index];
                        objCountryDialCodeSelectBoxSelector.appendChild(option);
                      }

                      // manually trigger an onchange event
                      const event = new Event('change');
                      objCountryDialCodeSelectBoxSelector.dispatchEvent(event);
                    }, 500);
                  }
                },
                label: t('Mobile phone number'),
                placeholder: t('Enter mobile phone number here'),
                type: 'phone_number',
                value: amplifySignInPhoneNumber
              },
              {
                hint: '',
                label: `${t('Password')} *`,
                placeholder: t('Enter password here'),
                type: 'password',
              },
            ]}
            headerText={t('Sign in')}
            hideSignUp
            slot="sign-in"
            style={{
              alignSelf: 'flex-start',
            }}
            submitButtonText={t('Sign in')}
            usernameAlias="phone_number"
          />

        </AmplifyAuthenticator>

        <div style={{
          fontSize: '14px',
          margin: 'auto',
          width: '425px'
          }}
        >
          {t('No account?')}
          {' '}
          <a href="https://admin.lirs.io/signup">
            {t('Sign up')}
          </a>

          <span style={{
            float: 'right',
            }}
          >
            {t('Forgot your password?')}
            {' '}
            <a href="https://admin.lirs.io/forgotpassword">
              {t('Reset password')}
            </a>
          </span>

        </div>

        <br />
        <br />

        <Language />

        <br />
        <br />

        <Footer />

        <br />
        <br />

      </div>

    );
  }

  // user is logged in
  return (
    <UserContext.Provider value={user}>
      <UserIdContext.Provider value={userId}>
        <Router>
          <Grommet theme={grommetThemeConfig}>

            <div id="container">

              <Header handleSignout={handleSignout} />

              <Main />

              <br />

              <Language />

              <br />
              <br />

              <Footer />

              <br />
              <br />

            </div>

          </Grommet>
        </Router>
      </UserIdContext.Provider>
    </UserContext.Provider>
  );
};

export default App;
