function TitleCase(str) {
  const splitStr = str.toLowerCase().split(' ');

  splitStr.forEach((element, index) => {
    splitStr[index] = element.charAt(0).toUpperCase() + element.substring(1);
  });

  // Directly return the joined string
  return splitStr.join(' ');
}

export default TitleCase;
