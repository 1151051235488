import React from 'react';
import { Box } from 'grommet';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18nParent from '../../i18n';

const Spinner = ({ spinnermessage = 'Loading', dots = '...' }) => {
  const { t } = useTranslation('translations', { i18nParent });

  return (
    <Box align="center" justify="center">
      <h2
        style={{
          fontSize: '16px',
          textAlign: 'center'
        }}
      >
        {`${t(spinnermessage)}`}
        {dots}
      </h2>
      <svg
        version="1.1"
        viewBox="0 0 32 32"
        width="28px"
        height="28px"
        fill="#004f97"
      >
        <path
          opacity=".25"
          d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
        />
        <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 16 16"
            to="360 16 16"
            dur="0.8s"
            repeatCount="indefinite"
          />
        </path>
      </svg>

    </Box>
  );
};

Spinner.propTypes = {
    spinnermessage: PropTypes.string,
    dots: PropTypes.string,
};

Spinner.defaultProps = {
  spinnermessage: 'Loading',
  dots: '...',
};

export default Spinner;
