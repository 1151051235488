import {
  Accordion,
  AccordionPanel,
  DataTable,
  Text
} from 'grommet';
import React from 'react';
import DOMPurify from 'dompurify';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import DATE_TIME_SETTINGS from '../../global/DateTimeSettings';
import FormatJson from '../Common/FormatJson';
import i18nParent from '../../i18n';
import TagContext from '../../global/TagContext';

const TagHistory = () => {
  const { t } = useTranslation('translations', { i18nParent });

  const { tagState } = React.useContext(TagContext);

  const { locations, history } = tagState;

  // prevents: Warning: Each child in a list should have a unique "key" prop
  // can occur if user enters two records within 1 minute
  // solution: modify the createdon to have additional spaces appended to make each unique
  const historyUniqueKey = history;

  let counter = 1;
  historyUniqueKey.forEach((obj, index) => {
    historyUniqueKey[index].id = counter;
    counter += 1;
  });

  // eslint-disable-next-line func-names
  let movement = '';

  let previousName = '';
  const movementNames = [];
  const movementDates = [];
  locations.forEach((obj) => {
    const date = new Date(obj.createdon);

    if (previousName !== obj.name) {
      movementNames.push(obj.name);
      movementDates.push(date.toLocaleDateString(t('en-au'), DATE_TIME_SETTINGS));

      previousName = obj.name;
    } else {
      movementDates[movementDates.length - 1] = `
        ${movementDates[movementDates.length - 1]}
        <br />
        ${date.toLocaleDateString(t('en-au'), DATE_TIME_SETTINGS)}
      `;
    }
  });

  const movementArray = [];
  movementNames.forEach((movementName, index) => {
    movementArray.push(`<a data-tip="${movementDates[index]}" style="white-space: pre-line">${movementName}</a>`);
  });

  movement = movementArray.join(' -> ');

  if (movement === '') {
    movement = 'Animal has no recorded locations.';
  }

  // eslint-disable-next-line react/no-danger
  const movementHtml = <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(movement) }} />;

  return (
    <div style={{ margin: '0px auto', textAlign: 'left' }}>

      <Accordion>

        <AccordionPanel key="movement_history" label={t('Movement history')}>

          <div style={{ marginLeft: '30px', marginRight: '30px', marginBottom: '30px' }}>
            {movementHtml}
          </div>

        </AccordionPanel>

        <AccordionPanel key="tag_history" label={t('Tag history')}>

          <DataTable
            alignSelf="center"
            columns={[
              {
                header: <Text>{t('ID')}</Text>,
                primary: true,
                property: 'id',
              },
              {
                header: <Text>{t('Date and time')}</Text>,
                property: 'created',
                // added a hidden random number here to ensure that the primaryKey column is always unique
                render: (datum) => {
                  const { createdon } = datum;

                  return (
                    <Text>
                      {new Date(createdon).toLocaleDateString(t('en-au'), DATE_TIME_SETTINGS)}
                    </Text>
                  );
                }
              },
              {
                header: <Text>{t('Value')}</Text>,
                render: (datum) => {
                  const { value } = datum;

                  return (
                    // eslint-disable-next-line react/no-danger
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(FormatJson(value, t)) }} />
                  );
                },
                property: 'value',
              },
            ]}
            data={history}
            key="tag_history"
            primaryKey="id"
            sortable
          />

        </AccordionPanel>

      </Accordion>

      <ReactTooltip place="top" type="info" effect="float" multiline />

    </div>
  );
};

export default TagHistory;
