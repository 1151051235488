import React from 'react';
import {
 Heading,
 Select
} from 'grommet';
import { useTranslation } from 'react-i18next';
import Animal from './Animal';
import Condition from './Condition';
import Insemination from './Insemination';
import i18nParent from '../../i18n';
import Lactation from './Lactation';
import Pregnancy from './Pregnancy';
import Vaccination from './Vaccination';
import Weight from './Weight';
import TagContext from '../../global/TagContext';
import KeyPairsToOptions from './KeyPairsToOptions';
import FormLookupListsApi from './FormLookupListsApi';
import ActiveOptions from './ActiveOptions';
import TitleCase from '../Common/TitleCase';
import Spinner from '../Common/Spinner';

const Forms = () => {
  const { t, i18n } = useTranslation('translations', { i18nParent });
  const { language } = i18n;

  // if user switches language whilst viewing tag information then we want to hide all tag information
  // e.g. make not found so that they have to search again
  // otherwise tag context will continue to be other language
  // and lookup lists in forms
  const [tagLanguage, setTagLanguage] = React.useState(language);

  const { tagState } = React.useContext(TagContext);
  const { properties } = tagState;

  const [lookupLists, setLookupLists] = React.useState(null);

  // form
  const [defaultForm, setDefaultForm] = React.useState({ label: '', value: '' }); // required for method to reset form
  const [form, setForm] = React.useState(null);
  const [formOptions, setFormOptions] = React.useState(null);
  const [sex, setSex] = React.useState(Object.prototype.hasOwnProperty.call(properties, 'sex') ? properties.sex : null);

  if (tagLanguage !== language) {
    // reset
    setForm(null);
    setFormOptions(null);
    setLookupLists(null);
    setTagLanguage(language);
    return (<Spinner />);
  }

  // get lookups
  // 1. initialise lookup list
  // 2. user changed language
  if (lookupLists === null) {
    FormLookupListsApi('form', language).then((response) => {
      setLookupLists(response);
    });

    return (<Spinner />);
  }

  if (formOptions == null || sex !== properties.sex) {
    const filteredFormKeypairs = lookupLists.keypairs.form;

    if (Object.prototype.hasOwnProperty.call(properties, 'sex')) {
      // prevents female only and non-nuetered forms from appearing on non-female animals
      // let formOptions = [
      //    t('Animal'),
      //    t('Condition'),
      //    t('Insemination'),
      //    t('Lactation'),
      //    t('Pregnancy'),
      //    t('Vaccination'),
      //    t('Weight')
      // ];
      // N.B. properties keys are in english
      // N.B. properties.sex.value = undefined if not specified
      if (
        properties.sex.value === t('Male')
        || (
          properties.sex.value === t('Female')
          && Object.prototype.hasOwnProperty.call(properties, 'neutered')
        )
      ) {
        delete filteredFormKeypairs[4];
        delete filteredFormKeypairs[5];
        delete filteredFormKeypairs[6];
      }
    }

    const defaultFormOptions = KeyPairsToOptions(ActiveOptions(filteredFormKeypairs, lookupLists.activekeys.form));

    defaultFormOptions.forEach((obj, key) => {
      defaultFormOptions[key].label = TitleCase(defaultFormOptions[key].label);
    });

    setFormOptions(defaultFormOptions);
    setSex(properties.sex);
    return (<Spinner />);
  }

  if (Object.prototype.hasOwnProperty.call(lookupLists.defaults, 'form') && lookupLists.defaults.form !== defaultForm) {
    const defaultForm2 = lookupLists.defaults.form;
    defaultForm2.label = TitleCase(defaultForm);

    setDefaultForm(defaultForm2);
    return (<Spinner />);
  }

  if (form === null) {
    setForm(defaultForm);
    return (<Spinner />);
  }

  // used to hide the form after form submission
  const resetForm = () => {
    setForm(defaultForm);
    return false;
  };

  // SELECT id, en FROM form ORDER BY id ASC;
  //
  //  id |      en
  // ----+--------------
  //   1 | form
  //   2 | animal
  //   3 | condition
  //   4 | insemination
  //   5 | lactation
  //   6 | pregnancy
  //   7 | vaccination
  //   8 | weight
  // (8 rows)

  // we are using numbers because more reliable than ensuring that the translations of labels
  // in database and frontend match
  const renderForm = () => {
    // break;
    // no necessary since unreachable code after return
    switch (parseInt(form.value, 10)) {
      case 2:
        return <Animal resetForm={resetForm} />;
      case 3:
        return <Condition resetForm={resetForm} />;
      case 4:
        return <Insemination resetForm={resetForm} />;
      case 5:
        return <Lactation resetForm={resetForm} />;
      case 6:
        return <Pregnancy resetForm={resetForm} />;
      case 7:
        return <Vaccination resetForm={resetForm} />;
      case 8:
        return <Weight resetForm={resetForm} />;
      default:
        return '';
    }
  };

  return (
    <div style={{ margin: '0px auto', textAlign: 'center' }}>

      <Heading level="4" margin="xsmall" size="xsmall">{t('Form')}</Heading>

      <Select
        alignSelf="center"
        labelKey="label"
        margin="0px center"
        multiple={false}
        onChange={
          ({ option }) => {
            setForm(option);
          }
        }
        options={formOptions}
        placeholder={t('Select a form')}
        value={form}
        valueKey="value"
      />

      <br />
      <br />

      {renderForm()}

      <br />

    </div>
  );
};

export default Forms;
