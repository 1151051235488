// common function
import RoundToPrecision from './RoundToPrecision';

const UseMyCurrentLocation = async () => {
  const GetPosition = await function GeoLocate(options) {
    return new Promise(((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    }));
  };

  const position = await GetPosition()
  .then((position2) => (
    {
      latitude: Math.floor(RoundToPrecision(position2.coords.latitude, 0.00001) * 100000) / 100000,
      longitude: Math.floor(RoundToPrecision(position2.coords.longitude, 0.00001) * 100000) / 100000
    }
    ))
  .catch({ latitude: 0, longitude: 0 });

  return position;
};

export default UseMyCurrentLocation;
