// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ap-southeast-2',
  aws_content_delivery_bucket: 'rfidfrontend-20190617184658-hostingbucket-dev',
  aws_content_delivery_bucket_region: 'ap-southeast-2',
  aws_content_delivery_url: 'https://d2iczlggpfnrb9.cloudfront.net',
  aws_cognito_region: 'ap-southeast-1',
  aws_user_pools_id: 'ap-southeast-1_IplMoKq6f',
  aws_user_pools_web_client_id: '46auvh8sfa9idh5dhspdj0j43f',
  federationTarget: 'COGNITO_USER_POOLS',
  API: {
    endpoints: [
        {
            name: 'lirs',
            endpoint: process.env.REACT_APP_GRAPHQL_HOST,
            region: 'ap-southeast-2',
        },
    ]
  }
};

export default awsmobile;
