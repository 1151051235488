import React from 'react';

import {
  Text,
} from 'grommet';
import { useTranslation } from 'react-i18next';
import i18nParent from '../../i18n';

// initialisation
const today = new Date();
const year = today.getFullYear();

const Footer = () => {
  const { t } = useTranslation('translations', { i18nParent });

  return (
    <div style={{ margin: '0px auto', textAlign: 'center' }}>

      <Text margin="small" size="xsmall" textAlign="center">
        {t('Livestock Identification Registration System (LIRS)')}
      </Text>

      <Text margin="small" size="xsmall" textAlign="center">
        <a href="https://admin.lirs.io" title={t('Administration')} target="_blank" rel="noopener noreferrer">
          {t('Admin')}
          &nbsp;&gt;
        </a>
      </Text>

      <br />

      <Text margin="small" size="xsmall" textAlign="center">
        {`© ${t('Copyright')}`}
        {` ${year} `}
        <a href="https://www.ausvet.com.au" title={t("Visit Ausvet's website")} target="_blank" rel="noopener noreferrer">
          {t('Ausvet')}
        </a>
        {'. '}
        {t('All rights reserved.')}
      </Text>

    </div>
  );
};

export default Footer;
