// issue with Chrome and toLocaleDateString
// weekday: 'long',
// month: 'long',

const DATE_SETTINGS = {
  day: 'numeric',
  month: '2-digit',
  year: 'numeric',
};

export default DATE_SETTINGS;
