import React, { useState } from 'react';

import {
  Button,
  Form,
  FormField,
  Heading,
  TextInput
} from 'grommet';
import MaterialIcon from 'material-icons-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18nParent from '../../i18n';

import RecordTagHistoryApi from './RecordTagHistoryApi';
import TagContext from '../../global/TagContext';

const Insemination = (props) => {
  const { resetForm } = props;

  const { t, i18n } = useTranslation('translations', { i18nParent });
  const { language } = i18n;

  const { tagState, tagDispatch } = React.useContext(TagContext);
  const { tagid, taglocationid } = tagState;

  // form inputs
  const [batchNumberValue, setBatchNumberValue] = useState('');

  return (
    <div style={{ margin: '0px auto', textAlign: 'center' }}>
      <Heading level="4" margin="xsmall" size="xsmall">{t('Insemination')}</Heading>

      <Form onSubmit={
          ({ value }) => {
            const value2 = {
              insemination: value
            };

            // record tag history
            RecordTagHistoryApi(language, tagid, taglocationid, value2).then((response) => {
              // redoing the whole history here because
              // we need to translate the form and form fields
              const payload = {
                history: response.history,
                properties: response.properties,
              };

              // update tag context
              tagDispatch({
                type: 'updateTagHistory',
                payload,
              });

              resetForm();
            });
          }
        }
      >

        <FormField
          label={t('Batch number')}
        >
          <TextInput
            name="batch_number"
            onChange={
              (event) => {
                setBatchNumberValue(event.target.value);
              }
            }
            required
            value={batchNumberValue}
          />
        </FormField>

        <br />

        <Button
          alignSelf="center"
          className="button"
          icon={<MaterialIcon icon="save" color="white" />}
          label={t('Save')}
          primary
          type="submit"
        />
      </Form>

      <br />

    </div>
  );
};

Insemination.propTypes = {
  resetForm: PropTypes.func.isRequired
};

export default Insemination;
