import API from '@aws-amplify/api';
import { Auth } from 'aws-amplify';

async function FormLookupListsApi(form, language) {
  const apiName = 'lirs';
  const path = '/form-lookup-lists';

  const myInit = {
    headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
    body: {
      form,
      language: language.substr(0, 2),
    },
  };

  return API.post(apiName, path, myInit);
}

export default FormLookupListsApi;
