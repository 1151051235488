// issue with Chrome and toLocaleDateString
// weekday: 'long',
// month: 'long',

const DATE_TIME_SETTINGS = {
  day: 'numeric',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export default DATE_TIME_SETTINGS;
