import SentenceCase from './SentenceCase';

const FormatKey = (key, t) => {
  // replace the underscore with blank space
  let formattedKey = key.split('_').join(' ');

  // for acronyms we want capital letters, not title case
  const acronymns = ['eid', 'vid'];

   // hack to filter out three letter acronyms
   if (!acronymns.includes(formattedKey)) {
       // capitalise the words between blank space
    formattedKey = SentenceCase(formattedKey);
   } else {
     // capitalise acronym
     formattedKey = formattedKey.toUpperCase();
   }

   return t(formattedKey);
};

export default FormatKey;
