import FormatKey from './FormatKey';
import DATE_SETTINGS from '../../global/DateSettings';

const FormatJson2 = (obj, t) => {
  // no parameter reassigment
  const obj2 = obj[Object.keys(obj)[0]];

  let output = '';

  const listRow = [];
  Object.keys(obj2).forEach((formField) => {
    // add the form field label
    obj2[formField].name = formField;

    // add all the
    listRow.push(obj2[formField]);
  });

  listRow.sort((a, b) => {
    if (a.ordinal < b.ordinal) {
      return -1;
    }

    if (a.ordinal === b.ordinal) {
      return 0;
    }

    return 1;
  });

  const isoDatePattern = /\d{4}-\d{2}-\d{2}/;
  listRow.forEach((obj3) => {
    // is value a date
    if (isoDatePattern.test(obj3.value)) {
      // date
      const date = new Date(obj3.value);

      output += `<strong>${FormatKey(obj3.name, t)}</strong>:
      ${date.toLocaleDateString(t('en-au'), DATE_SETTINGS)}<br>`;
    } else if (typeof obj3.value === 'boolean') {
      // boolean

      output += `${FormatKey(obj3.name, t)}<br>`;
    } else if (['age_time_unit', 'dose_quantity_unit', 'pregnancy_time_unit'].includes(obj3.name)) {
      // for unit we just append the unit to previous ordinal field
      // e.g. Age: 1 Years (although not including these two fields)
      output += ` ${obj3.value}`;
    } else if (['weight'].includes(obj3.name)) {
      // only have kilograms in labels
      output += `<strong>${FormatKey(obj3.name, t)}</strong>: ${obj3.value} ${t('kilograms')}<br>`;
    } else {
      output += `<strong>${FormatKey(obj3.name, t)}</strong>: ${obj3.value}<br>`;
    }
  });

  return output;
};

export default FormatJson2;
