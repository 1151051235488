const ActiveOptions = (optionsRaw, allowedKeys) => {
  const activeOptions = {};

  allowedKeys.forEach((key) => {
    // require this for cascade filtering (e.g. species, breed)
    if (key in optionsRaw) {
      activeOptions[key] = optionsRaw[key];
    }
  });

  return activeOptions;
};

export default ActiveOptions;
