const RemoveEmptyKeyPairs = (obj) => {
  // required - no parameter reassignment
  const obj2 = obj;

  Object.keys(obj2).forEach((key) => {
    if (obj2[key] === null || obj2[key] === undefined || obj2[key] === '') {
      // empty text inputs
      delete obj2[key];
    } else if (typeof obj2[key] === 'object' && obj2[key].value === '') {
      // unselected lookup inputs
      delete obj2[key];
    }
  });
  return obj2;
};

export default RemoveEmptyKeyPairs;
