import React from 'react';
import { Button } from 'grommet';
import MaterialIcon from 'material-icons-react';
import { useTranslation } from 'react-i18next';
import API from '@aws-amplify/api';
import { Auth } from 'aws-amplify';
import TagContext from '../../global/TagContext';
import LocationContext from '../../global/LocationContext';
import i18nParent from '../../i18n';
import Spinner from '../Common/Spinner';

async function recordTagLocationApi(language, latitude, longitude, nearbylocationid, nearbylocationname, tagid) {
  const apiName = 'lirs';
  const path = '/record-tag-location';

  const myInit = {
    headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
    body: {
      language: language.substr(0, 2),
      latitude,
      longitude,
      nearbylocationid,
      nearbylocationname,
      tagid,
      username: `${(await Auth.currentSession()).getIdToken().payload['cognito:username']}`,
    },
  };

  return API.post(apiName, path, myInit);
}

const Location = () => {
  const { t, i18n } = useTranslation('translations', { i18nParent });
  const { language } = i18n;

  const { locationState } = React.useContext(LocationContext);
  const {
    coords,
    nearbylocationid,
    nearbylocationname,
  } = locationState;

  // required if this renders before nearby locations lookup not complete
  const latitude = coords ? coords.latitude : 0;
  const longitude = coords ? coords.longitude : 0;

  const { tagState, tagDispatch } = React.useContext(TagContext);
  const {
    locations,
    tagid,
    taglocationid
  } = tagState;

  const [updateTagLocationInProgress, setUpdateTagLocationInProgress] = React.useState(false);

  // no location currently specified in select
  if (!nearbylocationid) {
    return (<span>{t('No location selected.')}</span>);
  }

  if (taglocationid == null && updateTagLocationInProgress === false) {
    // user has not recorded animals current location
    return (
      <div style={{ margin: '0px auto', textAlign: 'center' }}>
        <Button
          className="button"
          icon={<MaterialIcon icon="add_location" color="white" />}
          label={t('Record location')}
          onClick={() => {
            setUpdateTagLocationInProgress(true);

            // record tag location
            recordTagLocationApi(
              language,
              latitude,
              longitude,
              nearbylocationid,
              nearbylocationname,
              tagid
            ).then((response) => {
                // append tag locations with new location recorded
                const newLocations = locations;
                newLocations.push({ name: nearbylocationname, createdon: response.taglocationcreatedon });

                // set tag location id
                const payload = {
                  taglocationid: response.taglocationid,
                  locations: newLocations
                };

                // update tag context
                tagDispatch({
                  type: 'updateTagLocation',
                  payload,
                });
            });
          }}
          style={{ marginTop: '1em', marginBottom: '1em', minWidth: '200px' }}
        />
      </div>
    );
  }

  if (updateTagLocationInProgress === true) {
    return <Spinner />;
  }

  return ('');
};

export default Location;
