import {
  DataTable,
  Text
} from 'grommet';
import React from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DATE_TIME_SETTINGS from '../../global/DateTimeSettings';
import FormatJson2 from '../Common/FormatJson2';
import i18nParent from '../../i18n';

const TagVaccinationHistory = (props) => {
  const { t } = useTranslation('translations', { i18nParent });

  const { history } = props;

  // prevents: Warning: Each child in a list should have a unique "key" prop
  // can occur if user enters two records within 1 minute
  // solution: modify the createdon to have additional spaces appended to make each unique
  // remove the animal tag history
  const historyUniqueKey = history.filter((obj) => Object.keys(obj.value)[0] === 'vaccination');

  let counter = 1;
  historyUniqueKey.forEach((obj, index) => {
    historyUniqueKey[index].id = counter;
    counter += 1;
  });

  return (
    <div style={{ margin: '0px auto', textAlign: 'left' }}>

      <DataTable
        alignSelf="center"
        columns={[
          {
            header: <Text>{t('ID')}</Text>,
            primary: true,
            property: 'id',
          },
          {
            header: <Text>{t('Date and time')}</Text>,
            property: 'created',
            // added a hidden random number here to ensure that the primaryKey column is always unique
            render: (datum) => {
              const { createdon } = datum;

              return (
                <Text>
                  {new Date(createdon).toLocaleDateString(t('en-au'), DATE_TIME_SETTINGS)}
                </Text>
              );
            }
          },
          {
            header: <Text>{t('Value')}</Text>,
            render: (datum) => {
              const { value } = datum;

              return (
                // eslint-disable-next-line react/no-danger
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(FormatJson2(value, t)) }} />
              );
            },
            property: 'value',
          },
        ]}
        data={historyUniqueKey}
        key="tag_vaccination_history"
        primaryKey="id"
        sortable
        style={{ fontSize: '10px', width: '100%' }}
      />

    </div>
  );
};

TagVaccinationHistory.defaultProps = {
  history: null,
};

TagVaccinationHistory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.array,
};

export default TagVaccinationHistory;
