import React, { useState } from 'react';

import {
  Button,
  CheckBox,
  Form,
  FormField,
  Heading,
  MaskedInput,
  RadioButtonGroup,
  Select,
  Text,
  TextInput,
} from 'grommet';
import MaterialIcon from 'material-icons-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18nParent from '../../i18n';
import Range from '../Common/Range';
import RemoveEmptyKeyPairs from '../Common/RemoveEmptyKeyPairs';
import TagContext from '../../global/TagContext';
import Spinner from '../Common/Spinner';

import DATE_SETTINGS from '../../global/DateSettings';
import RecordTagHistoryApi from './RecordTagHistoryApi';
import KeyPairsToOptions from './KeyPairsToOptions';
import FormLookupListsApi from './FormLookupListsApi';
import ActiveOptions from './ActiveOptions';

const CalculateApproximateBirthDate = (ageValue, ageTimeUnitValue, t) => {
  const today = new Date();

  const approximateBirthDate = new Date(today);
  if (ageTimeUnitValue.label === t('Weeks')) {
    // length of time ageValue defined in Weeks unit
    approximateBirthDate.setDate(today.getDate() - (ageValue * 7));
  } else if (ageTimeUnitValue.label === t('Months')) {
    // length of time ageValue defined in Months unit
    approximateBirthDate.setMonth(today.getMonth() - (ageValue));
  } else if (ageTimeUnitValue.label === t('Years')) {
    // length of time ageValue defined in Years unit
    approximateBirthDate.setYear(today.getUTCFullYear() - (ageValue));
  }

  return approximateBirthDate;
};

const ApproximateBirthDate = (props) => {
  const { t } = useTranslation('translations', { i18nParent });

  const { ageValue, ageTimeUnitValue } = props;

  // can only do an estimate if both the:
  // - ageValue
  // - ageTimeUnitValue
  if (ageValue !== '' && ageTimeUnitValue.label !== '') {
    const approximateBirthDate = CalculateApproximateBirthDate(ageValue, ageTimeUnitValue, t);

    // the FormField component worked the first time but not subsequent times
    return (
      <div>
        {t('Approximate birth date')}
        <div style={{ textAlign: 'center' }}>

          <strong>{ approximateBirthDate.toLocaleDateString(t('en-au'), DATE_SETTINGS)}</strong>
        </div>
      </div>
    );
  }

  // unable to create an estimate
  return <Text />;
};

ApproximateBirthDate.propTypes = {
  ageValue: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  ageTimeUnitValue: PropTypes.object.isRequired,
};

const Animal = (props) => {
  const { resetForm } = props;

  const { t, i18n } = useTranslation('translations', { i18nParent });
  const { language } = i18n;

  const { tagState, tagDispatch } = React.useContext(TagContext);
  const { properties, tagid, taglocationid } = tagState;

  const [lookupLists, setLookupLists] = React.useState({});
  const [breedOptions, setBreedOptions] = React.useState(null);
  const mess = 'Loading';

  // form inputs
  const [speciesValue, setSpeciesValue] = React.useState('');
  const [breedValue, setBreedValue] = React.useState('');
  const [sexValue, setSexValue] = React.useState('');
  const [neuteredChecked, setNeuteredChecked] = React.useState(
    Object.prototype.hasOwnProperty.call(properties, 'neutered')
  );

  let defaultForm2 = t('Age'); // default - first option
  let form2Options = [t('Age'), t('Exact birth date'), t('Estimate birth date')];
  if (Object.prototype.hasOwnProperty.call(properties, 'age')) {
    form2Options = [t('Age')];
  } else if (Object.prototype.hasOwnProperty.call(properties, 'birth_date')) {
    form2Options = [t('Exact birth date')];
    defaultForm2 = t('Exact birth date');
  } else if (Object.prototype.hasOwnProperty.call(properties, 'birth_month')) {
    form2Options = [t('Estimate birth date')];
    defaultForm2 = t('Estimate birth date');
  }
  const [form2, setForm2] = React.useState(defaultForm2);

  // get lookups
  if (Object.entries(lookupLists).length === 0) {
    FormLookupListsApi('animal', language).then((response) => {
      setLookupLists(response);
    });

    return (<Spinner spinnermessage={mess} />);
  }

  if (breedOptions == null) {
    // conditions for pre-populating breed options
    if (Object.prototype.hasOwnProperty.call(properties, 'species')) {
      // species defined
      if (Object.prototype.hasOwnProperty.call(properties, 'breed')) {
        // breed specified - can be from all breed, active or not
        setBreedOptions(
          KeyPairsToOptions(
            lookupLists.keypairs.breed[
              Object.keys(lookupLists.keypairs.species).find(
                (key) => lookupLists.keypairs.species[key] === properties.species.value
              )
            ]
          )
        );
      } else {
        // breed not specified - can only choose from active breeds
        setBreedOptions(
          KeyPairsToOptions(
            ActiveOptions(
              lookupLists.keypairs.breed[
                Object.keys(lookupLists.keypairs.species).find(
                  (key) => lookupLists.keypairs.species[key] === properties.species.value
                )
              ], lookupLists.activekeys.breed
            )
          )
        );
      }
    return (<Spinner spinnermessage={mess} />);
    }

    // species not defined
    // we don't populate breed until species specified

    // does species have a default
    if (Object.prototype.hasOwnProperty.call(lookupLists.defaults, 'species')) {
      setBreedOptions(
        KeyPairsToOptions(
          ActiveOptions(
            lookupLists.keypairs.breed[lookupLists.defaults.species.value], lookupLists.activekeys.breed
          )
        )
      );
    } else {
      // no species default
      setBreedOptions([]);
    }

    return (<Spinner spinnermessage={mess} />);
  }

  if (speciesValue === '') {
    if (Object.prototype.hasOwnProperty.call(properties, 'species')) {
      // already set
      const speciesId = parseInt(
        Object.keys(lookupLists.keypairs.species).find(
          (key) => lookupLists.keypairs.species[key] === properties.species.value
        ),
        10
      );
      setSpeciesValue({ label: properties.species.value, value: speciesId });
    } else if (Object.prototype.hasOwnProperty.call(lookupLists.defaults, 'species')) {
      // default
      setSpeciesValue(lookupLists.defaults.species);
    }

    return (<Spinner spinnermessage={mess} />);
  }

  if (breedValue === '') {
    if (Object.prototype.hasOwnProperty.call(properties, 'breed')) {
      // already set
      const breedId = parseInt(
        Object.keys(lookupLists.keypairs.breed[speciesValue.value]).find(
          (key) => lookupLists.keypairs.breed[speciesValue.value][key] === properties.breed.value
        ),
        10
      );
      setBreedValue({ label: properties.breed.value, value: breedId });
    } else if (Object.prototype.hasOwnProperty.call(lookupLists.defaults, 'breed')) {
      // default
      setBreedValue(lookupLists.defaults.breed);
    }
  }

  if (sexValue === '') {
    if (Object.prototype.hasOwnProperty.call(properties, 'sex')) {
      // already set
      const sexId = parseInt(
        Object.keys(lookupLists.keypairs.sex).find(
          (key) => lookupLists.keypairs.sex[key] === properties.sex.value
        ),
        10
      );
      setSexValue({ label: properties.sex.value, value: sexId });
    } else if (Object.prototype.hasOwnProperty.call(lookupLists.defaults, 'sex')) {
      // default
      setSexValue(lookupLists.defaults.sex);
    }
  }

  const renderForm = () => {
    switch (form2) {
      case t('Age'):
        return <Age lookupLists={lookupLists} />;
      case t('Exact birth date'):
        return <ExactBirthDate />;
      default:
        return <EstimateBirthDate lookupLists={lookupLists} />;
    }
  };

  return (
    <div style={{ margin: '0px auto', textAlign: 'center' }}>
      <Heading margin="xsmall" level="4" size="xsmall">{t('Animal')}</Heading>

      <Form onSubmit={
          ({ value }) => {
            // required - no parameter reassignment
            let value2 = value;

            delete value2.form2;

            // only store neutered if true, otherwise don't record
            if ('neutered' in value2) {
              if (value2.neutered === false) {
                delete value2.neutered;
              }
            }

            // N.B. This needs to occur before the switch statement
            // remove blank non-required fields
            // e.g. breed
            value2 = RemoveEmptyKeyPairs(value2);

            // remove the form2 elements from the form2s which do not relate to the radio button selected
            // (for defining age of animal)
            // grommet form2 seems to remember form2 elements of previous child components (bug?)
            switch (form2) {
              case t('Age'):
                // age
                // age_time_unit
                // approximate_birth_date

                // add the approximate birth date
                // only if both specified
                if (
                  Object.prototype.hasOwnProperty.call(value2, 'age')
                  && Object.prototype.hasOwnProperty.call(value2, 'age_time_unit')
                ) {
                  // can only approximate birth date if both specified
                  if (value2.age !== '' && value2.age_time_unit.label !== '') {
                    const approximateBirthDate = CalculateApproximateBirthDate(value2.age, value2.age_time_unit, t);
                    value2.approximate_birth_date = approximateBirthDate.toISOString().substring(0, 10);
                  }
                } else if (
                  Object.prototype.hasOwnProperty.call(value2, 'age')
                  || Object.prototype.hasOwnProperty.call(value2, 'age_time_unit')
                ) {
                  // requires both inputs
                  delete value2.age;
                  delete value2.age_time_unit;
                }

                // remove other age related properties from form2 submission object

                // Exact birth date
                delete value2.birth_date;

                // estimate birth date
                delete value2.birth_month;
                delete value2.birth_year;

                break;
              case t('Exact birth date'):
                // birth_date

                if (Object.prototype.hasOwnProperty.call(value2, 'birth_date')) {
                  // ensure date valid
                  if (value2.birth_date.length === 10) {
                    // full specified date
                    // dd/mm/yyyy
                    const today = new Date();

                    const dateParts = value2.birth_date.split('/');

                    // month is 0-based, that's why we need dataParts[1] - 1
                    const date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

                    // ["dd", "mm", "yyyy"]
                    const day = parseInt(dateParts[0], 10);

                    // validation to ensure valid date
                    if (!(Boolean(+date) && date.getDate() === day)) {
                      // invalid date - date does not exist
                      delete value2.birth_date;
                    } else if (date > today) {
                      // invalid date - future date
                      delete value2.birth_date;
                    } else {
                      // valid date
                      // convert from dd/mm/yyyy to yyyy-mm-dd
                      value2.birth_date = date.toISOString().substring(0, 10);
                    }
                  } else {
                    // not filled out or only partially filled out
                    delete value2.birth_date;
                  }
                }

                // remove other age related properties from form2 submission object

                // age
                delete value2.age;
                delete value2.age_time_unit;

                // estimate birth date
                delete value2.birth_month;
                delete value2.birth_year;

                break;
              default:
                // birth_month
                // birth_year
                if (
                  !(
                    Object.prototype.hasOwnProperty.call(value2, 'birth_month')
                    && Object.prototype.hasOwnProperty.call(value2, 'birth_year')
                  )
                ) {
                  // requires both inputs
                  delete value2.birth_month;
                  delete value2.birth_year;
                }

                // remove other age related properties from form2 submission object

                // age
                delete value2.age;
                delete value2.age_time_unit;

                // Exact birth date
                delete value2.birth_date;

                break;
            }

            // remove any animal properties
            // already submitted
            Object.keys(properties).forEach((key) => {
              if (key in value2) {
                delete value2[key];
              }
            });

            // convert lookup lists from object ({label: "Male", value: 2}) to value
            if ('species' in value2) {
              // species
              value2.species = parseInt(value2.species.value, 10);
            }
            if ('breed' in value2) {
              value2.breed = parseInt(value2.breed.value, 10);
            }
            if ('sex' in value2) {
              value2.sex = parseInt(value2.sex.value, 10);
            }
            if ('age_time_unit' in value2) {
              value2.age_time_unit = parseInt(value2.age_time_unit.value, 10);
            }
            if ('birth_month' in value2) {
              value2.birth_month = parseInt(value2.birth_month.value, 10);
            }
            if ('birth_year' in value2) {
              value2.birth_year = parseInt(value2.birth_year.value, 10);
            }

            if (Object.keys(value2).length === 0 && value2.constructor === Object) {
              // nothing to submit - no new data
              // eslint-disable-next-line no-alert
              alert(t('Error: No new data to submit.'));
            } else {
              value2 = {
                animal: value2
              };

              // record tag history
              RecordTagHistoryApi(language, tagid, taglocationid, value2).then((response) => {
                // N.B. reset form must be before the tagDispatch
                // otherwise you will get an error with value and select
                resetForm();

                // redoing the whole history here because
                // we need to translate the form and form fields
                const payload = {
                  history: response.history,
                  properties: response.properties,
                };

                // update tag context
                tagDispatch({
                  type: 'updateTagHistory',
                  payload,
                });
              });

              return false;
            }

            return false;
          }
        }
      >
        <FormField
          label={t('Species')}
        >
          <Select
            alignSelf="center"
            disabled={Object.prototype.hasOwnProperty.call(properties, 'species')}
            labelKey="label"
            margin="0px center"
            multiple={false}
            name="species"
            onChange={
              ({ option }) => {
                setSpeciesValue({ label: lookupLists.keypairs.species[option.value], value: option.value });
                setBreedOptions(
                  KeyPairsToOptions(
                    ActiveOptions(
                      lookupLists.keypairs.breed[option.value], lookupLists.activekeys.breed
                    )
                  )
                );
              }
            }
            options={
              Object.prototype.hasOwnProperty.call(properties, 'species')
              ? KeyPairsToOptions(lookupLists.keypairs.species)
              : KeyPairsToOptions(ActiveOptions(lookupLists.keypairs.species, lookupLists.activekeys.species))
            }
            required={!Object.prototype.hasOwnProperty.call(properties, 'species')}
            value={speciesValue}
            valueKey="value"
          />
        </FormField>

        <FormField
          label={t('Breed')}
        >
          <Select
            alignSelf="center"
            disabled={Object.prototype.hasOwnProperty.call(properties, 'breed')}
            labelKey="label"
            margin="0px center"
            multiple={false}
            name="breed"
            onChange={
              ({ option }) => {
                setBreedValue({ label: lookupLists.keypairs.breed[option.value], value: option.value });
              }
            }
            options={breedOptions}
            value={breedValue}
            valueKey="value"
          />
        </FormField>

        <FormField
          label={t('Sex')}
        >
          <Select
            alignSelf="center"
            disabled={Object.prototype.hasOwnProperty.call(properties, 'sex')}
            labelKey="label"
            margin="0px center"
            multiple={false}
            name="sex"
            onChange={
              ({ option }) => {
                setSexValue({ label: lookupLists.keypairs.sex[option.value], value: option.value });
              }
            }
            options={
              Object.prototype.hasOwnProperty.call(properties, 'sex')
              ? KeyPairsToOptions(lookupLists.keypairs.sex)
              : KeyPairsToOptions(ActiveOptions(lookupLists.keypairs.sex, lookupLists.activekeys.sex))
            }
            required={!Object.prototype.hasOwnProperty.call(properties, 'sex')}
            value={sexValue}
            valueKey="value"
          />
        </FormField>

        <FormField
          label={t('Neutered')}
          style={{ textAlign: 'center', display: 'inline-block' }}
        >
          <CheckBox
            alignSelf="center"
            checked={neuteredChecked}
            disabled={Object.prototype.hasOwnProperty.call(properties, 'neutered')}
            name="neutered"
            onChange={(event) => setNeuteredChecked(event.target.checked)}
          />
        </FormField>

        <br />
        <br />

        <FormField
          label={t('Estimate age by')}
        >
          <RadioButtonGroup
            name="form2"
            onChange={
              (event) => setForm2(event.target.value)
            }
            options={form2Options}
            style={{ margin: '0px auto' }}
            value={form2}
          />
        </FormField>

        <br />

        <hr />

        {renderForm(form2, lookupLists)}

        <hr />

        <br />

        <Button
          alignSelf="center"
          className="button"
          icon={<MaterialIcon icon="save" color="white" />}
          label={t('Save')}
          primary
          style={{ margin: '0px auto' }}
          type="submit"
        />
      </Form>

      <br />

    </div>
  );
};

Animal.propTypes = {
  resetForm: PropTypes.func.isRequired
};

const Age = (props) => {
  const { lookupLists } = props;

  const { t } = useTranslation('translations', { i18nParent });

  const { tagState } = React.useContext(TagContext);
  const { properties } = tagState;

  // form inputs
  const [ageValue, setAgeValue] = useState(
    Object.prototype.hasOwnProperty.call(properties, 'age') ? properties.age.value : ''
  );
  const [ageTimeUnitValue, setAgeTimeUnitValue] = useState('');

  if (ageTimeUnitValue === '') {
    if (Object.prototype.hasOwnProperty.call(properties, 'age_time_unit')) {
      // already set
      const ageTimeUnitId = parseInt(
        Object.keys(lookupLists.keypairs.age_time_unit).find(
          (key) => lookupLists.keypairs.age_time_unit[key] === properties.age_time_unit.value
        ),
        10
      );
      setAgeTimeUnitValue({ label: properties.age_time_unit.value, value: ageTimeUnitId });
    } else if (Object.prototype.hasOwnProperty.call(lookupLists.defaults, 'age_time_unit')) {
      // default
      setAgeTimeUnitValue(lookupLists.defaults.age_time_unit);
    }
  }

  return (
    <div>
      <FormField
        label={t('Age')}
      >
        <TextInput
          disabled={Object.prototype.hasOwnProperty.call(properties, 'age')}
          name="age"
          onChange={
            (event) => {
              setAgeValue(event.target.value);
            }
          }
          value={ageValue}
        />
      </FormField>

      <FormField
        label={t('Age time unit')}
      >
        <Select
          alignSelf="center"
          disabled={Object.prototype.hasOwnProperty.call(properties, 'age_time_unit')}
          labelKey="label"
          margin="0px center"
          multiple={false}
          name="age_time_unit"
          onChange={
            ({ option }) => {
              setAgeTimeUnitValue({ label: lookupLists.keypairs.age_time_unit[option.value], value: option.value });
            }
          }
          options={
            Object.prototype.hasOwnProperty.call(properties, 'age_time_unit')
            ? KeyPairsToOptions(lookupLists.keypairs.age_time_unit)
            : KeyPairsToOptions(ActiveOptions(lookupLists.keypairs.age_time_unit, lookupLists.activekeys.age_time_unit))
          }
          value={ageTimeUnitValue}
          valueKey="value"
        />
      </FormField>

      <br />
      <br />

      {
        (
          () => {
            if (ageValue && ageTimeUnitValue) {
              return (<ApproximateBirthDate ageValue={ageValue} ageTimeUnitValue={ageTimeUnitValue} />);
            }

            return false;
          }
        )()
      }

    </div>
  );
};

Age.defaultProps = {
  lookupLists: { keypairs: [], defaults: [] }
};

Age.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lookupLists: PropTypes.object
};

const ExactBirthDate = () => {
  const { t } = useTranslation('translations', { i18nParent });

  const { tagState } = React.useContext(TagContext);
  const { properties } = tagState;

  // form inputs
  const [birthDateValue, setBirthDateValue] = useState('');

  if (Object.prototype.hasOwnProperty.call(properties, 'birth_date')) {
    const date = new Date(properties.birth_date.value);

    return (
      <Text>{date.toLocaleDateString(t('en-au'), DATE_SETTINGS)}</Text>
    );
  }

  // removed the grommet v2 Calendar component
  // the Month name was not translated in different languages
  // https://github.com/grommet/grommet-site/issues/128
  // this was likely a chrome issue with the toLocaleDateString method
  //
  // using a maskedinput component instead

  const today = new Date();
  const yearOptions = Range(today.getFullYear() - 20, today.getFullYear(), 1).reverse();

  return (
    <FormField
      label={t('Age time unit')}
    >
      <MaskedInput
        alignSelf="center"
        label={t('Birth date')}
        mask={[
          {
            length: 2,
            options: [
              '01',
              '02',
              '03',
              '04',
              '05',
              '06',
              '07',
              '08',
              '09',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '30',
              '31'
            ],
            regexp: /^[0-3][0-9]$/,
            placeholder: 'dd',
          },
          { fixed: '/' },
          {
            length: 2,
            options: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            regexp: /^[0-1][1-9]$/,
            placeholder: 'mm',
          },
          { fixed: '/' },
          {
            length: 4,
            options: yearOptions,
            regexp: /^[1-2][0-9][0-9][0-9]$/,
            placeholder: 'yyyy',
          },
        ]}
        name="birth_date"
        onChange={(event) => {
            setBirthDateValue(event.target.value);
          }}
        onSelect={(event) => {
          if (event.target.value.length === 10) {
            // full specified date
            // dd/mm/yyyy
            const dateParts = event.target.value.split('/');

            // month is 0-based, that's why we need dataParts[1] - 1
            const date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

            // ["dd", "mm", "yyyy"]
            const day = parseInt(dateParts[0], 10);

            // validation to ensure valid date
            if (!(Boolean(+date) && date.getDate() === day)) {
              // eslint-disable-next-line no-alert
              alert(t('Error: Invalid date.'));
              return false;
            }

            // validation to prevent future dates
            if (date > today) {
              // eslint-disable-next-line no-alert
              alert(t('Error: Cannot choose a future date.'));
              return false;
            }
          }

          return false;
        }}
        value={birthDateValue}
      />
    </FormField>
  );
};

const EstimateBirthDate = (props) => {
  const { lookupLists } = props;

  const { t } = useTranslation('translations', { i18nParent });

  const { tagState } = React.useContext(TagContext);
  const { properties } = tagState;

  // form inputs
  const [birthMonthValue, setBirthMonthValue] = useState('');
  const [birthYearValue, setBirthYearValue] = useState('');

  if (birthMonthValue === '') {
    if (Object.prototype.hasOwnProperty.call(properties, 'birth_month')) {
      // already set
      const birthMonthId = parseInt(
        Object.keys(lookupLists.keypairs.birth_month).find(
          (key) => lookupLists.keypairs.birth_month[key] === properties.birth_month.value
        ),
        10
      );
      setBirthMonthValue({ label: properties.birth_month.value, value: birthMonthId });
    } else if (Object.prototype.hasOwnProperty.call(lookupLists.defaults, 'birth_month')) {
      // default
      setBirthMonthValue(lookupLists.defaults.birth_month);
    }
  }

  if (birthYearValue === '') {
    if (Object.prototype.hasOwnProperty.call(properties, 'birth_year')) {
      setBirthYearValue({ label: properties.birth_year.value, value: properties.birth_year.value });
    } else {
      const currentDate = new Date();
      setBirthYearValue({ label: currentDate.getFullYear(), value: currentDate.getFullYear() });
    }
  }

  // years
  const today = new Date();
  const yearOptionsArray = Range(today.getFullYear() - 20, today.getFullYear(), 1).reverse();
  const yearOptions = [];
  yearOptionsArray.forEach((year) => {
    yearOptions.push({ label: year, value: year });
  });

  return (
    <div>
      <FormField
        label={t('Birth month')}
      >
        <Select
          alignSelf="center"
          disabled={Object.prototype.hasOwnProperty.call(properties, 'birth_month')}
          labelKey="label"
          margin="0px center"
          multiple={false}
          name="age_time_unit"
          onChange={
            ({ option }) => {
              setBirthMonthValue({ label: lookupLists.keypairs.birth_month[option.value], value: option.value });
            }
          }
          options={
            Object.prototype.hasOwnProperty.call(properties, 'birth_month')
            ? KeyPairsToOptions(lookupLists.keypairs.birth_month)
            : KeyPairsToOptions(ActiveOptions(lookupLists.keypairs.birth_month, lookupLists.activekeys.birth_month))
          }
          value={birthMonthValue}
          valueKey="value"
        />
      </FormField>

      <FormField
        label={t('Birth year')}
      >
        <Select
          alignSelf="center"
          disabled={Object.prototype.hasOwnProperty.call(properties, 'birth_year')}
          labelKey="label"
          margin="0px center"
          multiple={false}
          name="birth_year"
          onChange={
            ({ option }) => {
              setBirthYearValue({ label: option.value, value: option.value });
            }
          }
          options={yearOptions}
          value={birthYearValue}
          valueKey="value"
        />
      </FormField>

    </div>
  );
};

EstimateBirthDate.defaultProps = {
  lookupLists: { keypairs: [], defaults: [] }
};

EstimateBirthDate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lookupLists: PropTypes.object
};

export default Animal;
