import React from 'react';
import {
  Button,
  Heading,
  Form,
  FormField,
  Select
} from 'grommet';
import MaterialIcon from 'material-icons-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import i18nParent from '../../i18n';
import RecordTagHistoryApi from './RecordTagHistoryApi';
import TagContext from '../../global/TagContext';

const Condition = (props) => {
  const { resetForm } = props;

  const { t, i18n } = useTranslation('translations', { i18nParent });
  const { language } = i18n;

  const { tagState, tagDispatch } = React.useContext(TagContext);
  const { tagid, taglocationid } = tagState;

  // form inputs
  const [conditionValue, setConditionValue] = React.useState('');

  return (
    <div style={{ margin: '0px auto', textAlign: 'center' }}>
      <Heading level="4" margin="xsmall" size="xsmall">{t('Condition')}</Heading>

      <Form onSubmit={
          ({ value }) => {
            const value2 = {
              condition: value
            };

            // record tag history
            RecordTagHistoryApi(language, tagid, taglocationid, value2).then((response) => {
              // redoing the whole history here because
              // we need to translate the form and form fields
              const payload = {
                history: response.history,
                properties: response.properties,
              };

              // update tag context
              tagDispatch({
                type: 'updateTagHistory',
                payload,
              });

              resetForm();
            });
          }
        }
      >
        <FormField
          label={t('Condition')}
        >
          <Select
            alignSelf="center"
            margin="0px center"
            multiple={false}
            name="condition"
            onChange={
              ({ option }) => {
                setConditionValue(option.value);
              }
            }
            options={['1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5']}
            required
            value={conditionValue}
          />
        </FormField>

        <br />

        <Button
          alignSelf="center"
          className="button"
          icon={<MaterialIcon icon="save" color="white" />}
          label={t('Save')}
          primary
          type="submit"
        />
      </Form>

      <br />

    </div>
  );
};

Condition.propTypes = {
  resetForm: PropTypes.func.isRequired
};

export default Condition;
