const ChangeLanguage = (i18n, newLanguage) => {
  i18n.changeLanguage(newLanguage, (error, t) => {
    if (error) {
      // eslint-disable-next-line no-console
      console.log(t('Error: Unable to change language. \n\nError message: '), error);
    }
  });
};

export default ChangeLanguage;
