import React, { useState } from 'react';

import {
  Button,
  Form,
  FormField,
  Heading,
  Select,
  TextInput,
} from 'grommet';
import MaterialIcon from 'material-icons-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18nParent from '../../i18n';

import RecordTagHistoryApi from './RecordTagHistoryApi';
import KeyPairsToOptions from './KeyPairsToOptions';
import FormLookupListsApi from './FormLookupListsApi';
import ActiveOptions from './ActiveOptions';
import TagContext from '../../global/TagContext';
import DATE_SETTINGS from '../../global/DateSettings';
import Spinner from '../Common/Spinner';

const CalculateEstimatedConceptionDate = (lengthOfTimePregnant, timeUnitPregnant, t) => {
  const today = new Date();

  const estimatedConceptionDate = new Date(today);
  if (timeUnitPregnant.label === t('Weeks')) {
    // length of time age defined in Weeks unit
    estimatedConceptionDate.setDate(today.getDate() - (lengthOfTimePregnant * 7));
  } else if (timeUnitPregnant.label === t('Months')) {
    // length of time age defined in Months unit
    estimatedConceptionDate.setMonth(today.getMonth() - (lengthOfTimePregnant));
  }

  return estimatedConceptionDate;
};

const EstimatedConceptionDate = (props) => {
  const { t } = useTranslation('translations', { i18nParent });

  const { lengthOfTimePregnant, timeUnitPregnant } = props;

  // can only do an estimate if both the:
  // - lengthOfTimePregnant
  // - timeUnitPregnant
  if (lengthOfTimePregnant !== '' && timeUnitPregnant.label !== '') {
    const estimatedConceptionDate = CalculateEstimatedConceptionDate(lengthOfTimePregnant, timeUnitPregnant, t);

    // the FormField component worked the first time but not subsequent times
    return (
      <div>
        {t('Estimated conception date')}
        <div style={{ textAlign: 'center' }}>

          <strong>{ estimatedConceptionDate.toLocaleDateString(t('en-au'), DATE_SETTINGS)}</strong>
        </div>
      </div>
    );
  }

  // unable to create an estimate
  return '';
};

EstimatedConceptionDate.propTypes = {
  lengthOfTimePregnant: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  timeUnitPregnant: PropTypes.object.isRequired,
};

const Pregnancy = (props) => {
  const { resetForm } = props;

  const { t, i18n } = useTranslation('translations', { i18nParent });
  const { language } = i18n;

  const { tagState, tagDispatch } = React.useContext(TagContext);
  const { tagid, taglocationid } = tagState;

  const [lookupLists, setLookupLists] = React.useState({});

  // form inputs
  const [lengthOfTimePregnantValue, setLengthOfTimePregnantValue] = useState('');
  const [timeUnitPregnantValue, setTimeUnitPregnantValue] = useState('');

  // get lookups
  if (Object.entries(lookupLists).length === 0) {
    FormLookupListsApi('pregnancy', language).then((response) => {
      setLookupLists(response);
    });

    return (<Spinner />);
  }

  if (timeUnitPregnantValue === '') {
    if (Object.prototype.hasOwnProperty.call(lookupLists.defaults, 'pregnancy_time_unit')) {
      setTimeUnitPregnantValue(lookupLists.defaults.pregnancy_time_unit);
    }
  }

  return (
    <div style={{ margin: '0px auto', textAlign: 'center' }}>
      <Heading margin="xsmall" level="4" size="xsmall">{t('Pregnancy')}</Heading>

      <Form onSubmit={
          ({ value }) => {
            // unable to change parameters
            let value2 = value;

            // add the estimated conception date
            // only if both specified
            if (
              Object.prototype.hasOwnProperty.call(value2, 'length_of_time_pregnant')
              && Object.prototype.hasOwnProperty.call(value2, 'pregnancy_time_unit')
            ) {
              // can only estimated conception date if both specified
              if (value2.length_of_time_pregnant !== '' && value2.pregnancy_time_unit !== '') {
                const estimatedConceptionDate = CalculateEstimatedConceptionDate(
                  value2.length_of_time_pregnant,
                  value2.pregnancy_time_unit,
                  t
                );
                value2.estimated_conception_date = estimatedConceptionDate.toISOString().substring(0, 10);
              }
            }

            // convert lookup lists from labels to values
            if ('pregnancy_time_unit' in value2) {
              value2.pregnancy_time_unit = parseInt(value2.pregnancy_time_unit.value, 10);
            }

            value2 = {
              pregnancy: value2
            };

            // record tag history
            RecordTagHistoryApi(language, tagid, taglocationid, value2).then((response) => {
              // redoing the whole history here because
              // we need to translate the form and form fields
              const payload = {
                history: response.history,
                properties: response.properties,
              };

              // update tag context
              tagDispatch({
                type: 'updateTagHistory',
                payload,
              });

              resetForm();
            });
          }
        }
      >
        <FormField
          label={t('Length of time pregnant')}
        >
          <TextInput
            name="length_of_time_pregnant"
            onChange={
              (event) => {
                setLengthOfTimePregnantValue(event.target.value);
              }
            }
            required
            value={lengthOfTimePregnantValue}
          />
        </FormField>

        <FormField
          label={t('Pregnancy time unit')}
        >
          <Select
            alignSelf="center"
            labelKey="label"
            margin="0px center"
            multiple={false}
            name="pregnancy_time_unit"
            onChange={
              ({ option }) => {
                setTimeUnitPregnantValue(
                  {
                    label: lookupLists.keypairs.pregnancy_time_unit[option.value],
                    value: option.value
                  }
                );
              }
            }
            options={
              KeyPairsToOptions(
                ActiveOptions(lookupLists.keypairs.pregnancy_time_unit, lookupLists.activekeys.pregnancy_time_unit)
              )
            }
            required
            value={timeUnitPregnantValue}
            valueKey="value"
          />
        </FormField>

        <br />

        <EstimatedConceptionDate
          lengthOfTimePregnant={lengthOfTimePregnantValue}
          timeUnitPregnant={timeUnitPregnantValue}
        />

        <br />

        <Button
          alignSelf="center"
          className="button"
          icon={<MaterialIcon icon="save" color="white" />}
          label={t('Save')}
          primary
          type="submit"
        />
      </Form>

      <br />

    </div>
  );
};

Pregnancy.propTypes = {
  resetForm: PropTypes.func.isRequired
};

export default Pregnancy;
