const grommetThemeConfig = {
  global: {
    colors: {
      background: 'whitesmoke',
      active: 'gainsboro',
      brand: 'silver',
    },
    control: {
      color: 'darkgrey',
    },
    focus: {
      border: {
        color: 'darkgrey',
      }
    },
  },
  button: {
    border: {
      color: 'white',
      radius: '5px'
    },
    extend: 'margin: 0px auto; max-width: 95%;',
  },
  CheckBox: {
    extend: {
      margin: '0px auto',
      textAlign: 'center',
    }
  },
  checkBox: {
    color: 'rgb(0, 79, 151)',
  },
  formField: {
    label: {
      margin: '0px auto'
    }
  },
  heading: {
    extend: {
      color: 'rgb(0, 79, 151)',
      textAlign: 'center',
      maxWidth: 'none !important',
      fontWeight: 500,
      fontFamily: 'Montserrat, Helvetica, Arial, Lucida, sans-serif',
    }
  },
  select: {
  },
  radioButtonGroup: {
    borderColor: 'rgb(0, 79, 151)',
  },
  table: {
    header: {
      extend: 'text-align: center'
    }
  },
  text: {
    extend: {
      color: 'rgb(102, 102, 102)',
      fontWeight: 500,
      fontFamily: '"Maven Pro", Helvetica, Arial, Lucida, sans-serif',
    }
  },
  textInput: {
    extend: 'text-align: center;',
    container: {
      extend: 'text-align: center; max-width: 95%; margin: 0 auto;',
    },
  },
};

export default grommetThemeConfig;
