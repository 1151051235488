import React from 'react';

import { useTranslation } from 'react-i18next';

import i18nParent from '../../i18n';

import logo from '../../assets/images/logo.png';

const HeaderLogin = () => {
  const { t } = useTranslation('translations', { i18nParent });

  return (
    <div>
      <br />

      <h1
        style={{
          display: 'table',
          fontSize: '16px',
          margin: '0px auto'
        }}
      >

        <img
          alt="Logo"
          src={logo}
          style={{
            display: 'table-cell',
            marginRight: '16px',

          }}
        />
        <span
          style={{
            display: 'table-cell',
            textAlign: 'center',
            verticalAlign: 'middle',
          }}
        >
          {t('LIRS.io')}
        </span>

      </h1>

    </div>
  );
};

export default HeaderLogin;
