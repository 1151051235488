import React from 'react';

import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import FormatKey from '../Common/FormatKey';
import i18nParent from '../../i18n';
import DATE_SETTINGS from '../../global/DateSettings';

const TagInfo2 = (props) => {
  const {
    eid, locations, properties, vid
  } = props;

  const { t } = useTranslation('translations', { i18nParent });

  let output = '<div style="font-size: 12px;">';

  // eid and vid
  output += ` <strong>${t('EID')}</strong>: ${eid}`;
  output += ` <strong>${t('VID')}</strong>: ${vid}`;

  // remove age and age_time_unit
  // e.g. 1 Years as an age will change over time. e.g. in 1 more year age will be 2 Years
  const excludePropertiesFormFields = ['age', 'age_time_unit'];

  const listRow = [];
  Object.keys(properties).forEach((formField) => {
    if (!excludePropertiesFormFields.includes(formField)) {
      // add the form field label
      properties[formField].name = formField;

      // add all the
      listRow.push(properties[formField]);
    }
  });

  listRow.sort(
    (a, b) => {
      if (a.ordinal < b.ordinal) {
        return -1;
      }
      if (a.ordinal === b.ordinal) {
        return 0;
      }

      return 1;
    }
  );

  const isoDatePattern = /\d{4}-\d{2}-\d{2}/;
  listRow.forEach((obj) => {
    // is value a date
    if (isoDatePattern.test(obj.value)) {
      // date
      const date = new Date(obj.value);

      output += ` <strong>${FormatKey(obj.name, t)}</strong>: ${date.toLocaleDateString(t('en-au'), DATE_SETTINGS)}`;
    } else if (typeof obj.value === 'boolean') {
      // boolean
      output += ` (${FormatKey(obj.name, t)})`;
    } else if (['age_time_unit', 'dose_quantity_unit', 'pregnancy_time_unit'].includes(obj.name)) {
      // for unit we just append the unit to previous ordinal field
      // e.g. Age: 1 Years (although not including these two fields)
      output += ` ${obj.value}`;
    } else {
      output += ` <strong>${FormatKey(obj.name, t)}</strong>: ${obj.value}`;
    }
  });

  // last known location
  let lastKnownLocation = t('Unknown'); // default if no locations specified
  let lastKnownLocationDate = null; // default if no locations specified
  if (locations != null && locations.length > 0) {
    lastKnownLocation = locations[locations.length - 1].name;
    lastKnownLocationDate = locations[locations.length - 1].createdon;
  }
  output += ` <strong>${t('Last known location')}</strong>: ${lastKnownLocation}`;
  if (lastKnownLocationDate != null) {
    // date
    const date = new Date(lastKnownLocationDate);

    output += ` (${date.toLocaleDateString(t('en-au'), DATE_SETTINGS)})`;
  }

  output += '</div>';

  const outputHtml = (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(output) }}
      style={{ width: '100%', marginBottom: '6px' }}
    />
  );

  return (
    <div style={{ margin: '0px auto', textAlign: 'left' }}>
      {outputHtml}
    </div>
  );
};

TagInfo2.defaultProps = {
  eid: null,
  locations: null,
  properties: null,
  vid: null
};

TagInfo2.propTypes = {
  eid: PropTypes.string,
   // eslint-disable-next-line react/forbid-prop-types
  locations: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  properties: PropTypes.object,
  vid: PropTypes.string
};

export default TagInfo2;
