import React from 'react';

import PropTypes from 'prop-types';

const reducer = (state, action) => {
  const newNearbyLocations = state.nearbylocations;

  switch (action.type) {
    case 'setCoords':
      return {
        ...state,
        coords: action.payload.coords,
      };
    case 'setLocationId':
      return {
        ...state,
        nearbylocationid: action.payload.nearbylocationid
      };
    case 'setLocation':
      return {
        ...state,
        nearbylocationid: action.payload.value,
        nearbylocationname: action.payload.label,
      };
    case 'setNearbyLocations':
      return {
        ...state,
        nearbylocationid: action.payload.nearbylocationid,
        nearbylocationname: action.payload.nearbylocationname,
        nearbylocations: action.payload.nearbylocations,
      };
    case 'addNearbyLocation':
      newNearbyLocations.unshift({ label: action.payload.name, value: action.payload.id });

      return {
        ...state,
        nearbylocationid: action.payload.id,
        nearbylocationname: action.payload.name,
        nearbylocations: newNearbyLocations,
      };
    default:
      return state;
  }
};

const initialState = {
  coords: null,
  nearbylocationid: null,
  nearbylocationname: null,
  nearbylocations: null, // distinguish between no locations in this area vs not set yet
};

const LocationContext = React.createContext(initialState);

export const LocationProvider = (props) => {
  const [locationState, locationDispatch] = React.useReducer(reducer, initialState);
  const { children } = props;
  return (
    <LocationContext.Provider value={{ locationState, locationDispatch }}>
      {children}
    </LocationContext.Provider>
  );
};

LocationProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired
};

export default LocationContext;
