import FormatKey from './FormatKey';
import DATE_SETTINGS from '../../global/DateSettings';

const FormatJson = (obj, t) => {
  // no parameter reassigment
  const obj2 = obj;

  let output = '';

  // form
  const form = Object.keys(obj2)[0];
  output += `<strong>${FormatKey(form, t)}</strong>`;

  const listRow = [];
  Object.keys(obj2[form]).forEach((formField) => {
    // add the form field label
    obj2[form][formField].name = formField;

    // add all the
    listRow.push(obj2[form][formField]);
  });

  listRow.sort((a, b) => {
    if (a.ordinal < b.ordinal) {
      return -1;
    }

    if (a.ordinal === b.ordinal) {
      return 0;
    }

    return 1;
  });

  const isoDatePattern = /\d{4}-\d{2}-\d{2}/;
  listRow.forEach((obj3) => {
    // is value a date
    if (isoDatePattern.test(obj3.value)) {
      // date
      const date = new Date(obj3.value);

      output += `<br>&nbsp;&nbsp;&nbsp;&nbsp;${FormatKey(obj3.name, t)}:
      ${date.toLocaleDateString(t('en-au'), DATE_SETTINGS)}`;
    } else if (typeof obj3.value === 'boolean') {
      // boolean

      output += `<br>&nbsp;&nbsp;&nbsp;&nbsp;${FormatKey(obj3.name, t)}`;
    } else if (['age_time_unit', 'dose_quantity_unit', 'pregnancy_time_unit'].includes(obj3.name)) {
      // for unit we just append the unit to previous ordinal field
      // e.g. Age: 1 Years (although not including these two fields)
      output += ` ${obj3.value}`;
    } else if (['weight'].includes(obj3.name)) {
      // only have kilograms in labels
      output += `<br>&nbsp;&nbsp;&nbsp;&nbsp;${FormatKey(obj3.name, t)}: ${obj3.value} ${t('kilograms')}`;
    } else {
      output += `<br>&nbsp;&nbsp;&nbsp;&nbsp;${FormatKey(obj3.name, t)}: ${obj3.value}`;
    }
  });

  return output;
};

export default FormatJson;
