import React, { useState } from 'react';

import {
  Button,
  Form,
  FormField,
  Heading,
  Select
} from 'grommet';
import MaterialIcon from 'material-icons-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import i18nParent from '../../i18n';

import RecordTagHistoryApi from './RecordTagHistoryApi';
import KeyPairsToOptions from './KeyPairsToOptions';
import FormLookupListsApi from './FormLookupListsApi';
import ActiveOptions from './ActiveOptions';

import TagContext from '../../global/TagContext';
import Spinner from '../Common/Spinner';

const Lactation = (props) => {
  const { resetForm } = props;

  const { t, i18n } = useTranslation('translations', { i18nParent });
  const { language } = i18n;

  const { tagState, tagDispatch } = React.useContext(TagContext);
  const { tagid, taglocationid } = tagState;

  const [lookupLists, setLookupLists] = React.useState({});

  // form inputs
  const [lactationValue, setLactationValue] = useState('');

  const mess = 'Loading';

  // get lookups
  if (Object.entries(lookupLists).length === 0) {
    FormLookupListsApi('lactation', language).then((response) => {
      setLookupLists(response);
    });

    return (<Spinner spinnermessage={mess} />);
  }

  if (lactationValue === '') {
    if (Object.prototype.hasOwnProperty.call(lookupLists.defaults, 'lactation')) {
      setLactationValue(lookupLists.defaults.lactation);
    }
  }

  return (
    <div style={{ margin: '0px auto', textAlign: 'center' }}>
      <Heading margin="xsmall" level="4" size="xsmall">{t('Lactation')}</Heading>

      <Form onSubmit={
          ({ value }) => {
            let value2 = value;

            // convert lookup lists from labels to values
            if ('lactation' in value2) {
              value2.lactation = parseInt(value2.lactation.value, 10);
            }

            value2 = {
              lactation: value2
            };

            // record tag history
            RecordTagHistoryApi(language, tagid, taglocationid, value2).then((response) => {
              // redoing the whole history here because
              // we need to translate the form and form fields
              const payload = {
                history: response.history,
                properties: response.properties,
              };

              // update tag context
              tagDispatch({
                type: 'updateTagHistory',
                payload,
              });

              resetForm();
            });
          }
        }
      >

        <FormField
          label={t('Lactation')}
        >
          <Select
            alignSelf="center"
            labelKey="label"
            margin="0px center"
            multiple={false}
            name="lactation"
            onChange={
              ({ option }) => {
                setLactationValue({ label: lookupLists.keypairs.lactation[option.value], value: option.value });
              }
            }
            options={KeyPairsToOptions(ActiveOptions(lookupLists.keypairs.lactation, lookupLists.activekeys.lactation))}
            required
            value={lactationValue}
            valueKey="value"
          />
        </FormField>

        <br />

        <Button
          alignSelf="center"
          className="button"
          icon={<MaterialIcon icon="save" color="white" />}
          label={t('Save')}
          primary
          type="submit"
        />
      </Form>

      <br />

    </div>
  );
};

Lactation.propTypes = {
  resetForm: PropTypes.func.isRequired
};

export default Lactation;
