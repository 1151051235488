import React from 'react';

import TagContext from '../../global/TagContext';

import TagInfo2 from './TagInfo2';

const TagInfo = () => {
  const { tagState } = React.useContext(TagContext);

  const {
    eid, locations, properties, vid
  } = tagState;

  return (<TagInfo2 eid={eid} locations={locations} properties={properties} vid={vid} />);
};

export default TagInfo;
