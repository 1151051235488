import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Claim from './Claim';

import LocationSummary from './Common/LocationSummary';
import Tag from './Tag';
import { TagProvider } from '../global/TagContext';
import { LocationProvider } from '../global/LocationContext';

const Main = () => (
  <Switch>
    <Route path="/claim/:tagownerclaim_id" component={Claim} />
    <Route path="/:search" exact>
    <div>
      <LocationProvider>
        <TagProvider>

          <div className="mainBox">

            <LocationSummary />

            <br />

            <Tag />

          </div>

        </TagProvider>
      </LocationProvider>
    </div>
    </Route>
    <Route path="/" exact>
    <div>
      <LocationProvider>
        <TagProvider>

          <div className="mainBox">

            <LocationSummary />

            <br />

            <Tag />

          </div>

        </TagProvider>
      </LocationProvider>
    </div>
    </Route>
  </Switch>

);

export default Main;
