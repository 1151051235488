import API from '@aws-amplify/api';
import { Auth } from 'aws-amplify';

async function RecordTagHistoryApi(language, tagid, taglocationid, value) {
  const apiName = 'lirs';
  const path = '/record-tag-history';

  const myInit = {
    headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
    body: {
      language: language.substr(0, 2),
      tagid,
      taglocationid,
      username: `${(await Auth.currentSession()).getIdToken().payload['cognito:username']}`,
      value,
    },
  };

  return API.post(apiName, path, myInit);
}

export default RecordTagHistoryApi;
