const KeyPairsToOptions = (objInput) => {
  // required - no parameter reassignment
  const arrayOutput = [];

  Object.keys(objInput).forEach((keyInput) => {
    arrayOutput.push({ value: parseInt(keyInput, 10), label: objInput[keyInput] });
  });

  return arrayOutput;
};

export default KeyPairsToOptions;
