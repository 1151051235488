import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import { Button, Text, Box, Layer } from 'grommet';

async function fetchClaimData(tagOwnerClaimId, language) {
  const apiName = 'lirs';
  const path = '/fetch-claim-info';
  
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id: tagOwnerClaimId, language: language.substr(0, 2)}),
  };
  return await API.post(apiName, path, myInit);
}

async function transferOwnership(tagOwnerClaimId, language, isApprove) {
  const apiName = 'lirs'; // Use the same API name
  const path = '/transfer-ownership';
  const cognitoUsername = `${(await Auth.currentSession()).getIdToken().payload['cognito:username']}`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id: tagOwnerClaimId, language: language.substr(0, 2), isApprove: isApprove , username: cognitoUsername  }),
  };

  return  await API.post(apiName, path, myInit);
}

const Claim = () => {
  const { tagownerclaim_id } = useParams();
  const [claimData, setClaimData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [language, setLanguage] = useState('en');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  

  useEffect(() => {
    async function fetchAndSetData() {
      try {
        const data = await fetchClaimData(tagownerclaim_id, language);
        if(data.status == "success" ){
          setClaimData(data);
          setIsLoading(false);
        }else{
          setIsError(true);
          setIsLoading(false);
        }
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
      }
    }
    fetchAndSetData();
  }, [tagownerclaim_id, language]);

  const handleApprove = () => {
    setShowConfirmation(true);
  };

  const confirmTransfer = async () => {
  
    try {
      const response = await transferOwnership(tagownerclaim_id, language, true);

      // Assume success and directly fetch the updated data
      const updatedData = await fetchClaimData(tagownerclaim_id, language);
      setClaimData(updatedData);

      // Set success message regardless of the response structure
      setSuccessMessage(response.message || 'Ownership transferred successfully');
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    } finally {
      setShowConfirmation(false);
    }
  };
  

  const handleReject = async () => {
    try {
      const response = await transferOwnership(tagownerclaim_id, language, false);

      // Assume success and directly fetch the updated data
      const updatedData = await fetchClaimData(tagownerclaim_id, language);
      setClaimData(updatedData);

      // Set success message regardless of the response structure
      setSuccessMessage(response.message || 'Ownership transfer rejected');
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
  };
}

  const cancelTransfer = () => {
    setShowConfirmation(false);
  };

  if (isLoading) return <Text>Loading...</Text>;
  if (isError) return (
    
    <Box pad="small" round="small">
      <Text 
        size="large" 
        margin={{ bottom: 'medium' }} 
        style={{ color: 'rgb(0, 79, 151)' }}
        >
        The claim information is not found
      </Text>
    </Box>
    )

  return (
    <div>
      <h2>Transfer of Ownership Request</h2>
      {successMessage && (
        <Box pad="small" background="status-ok" round="small">
          <Text>{successMessage}</Text>
        </Box>
      )}
  
      {isLoading && <Text>Loading...</Text>}
      {isError && (
          <Box pad="small" round="small">
            <Text 
              size="large" 
              margin={{ bottom: 'medium' }} 
              style={{ color: 'rgb(0, 79, 151)' }}
              >
              The claim information is not found
            </Text>
          </Box>
          )
      }
  
      {!isLoading && !isError && claimData && (
        <>
          <Box pad="medium" background="light-2" round="small" margin={{ bottom: 'medium' }}>
            <h3>Tag: {claimData.tag_details?.vid}</h3>
            <p>Current Owner: {claimData.current_owner?.name}</p>
          </Box>

          {claimData.tagownerclaim && !claimData.tagownerclaim.approvedon && (
            <>
          <Text 
            size="large" 
            margin={{ bottom: 'small' }} 
            style={{ color: 'rgb(0, 79, 151)' }}
          >
            {claimData.claiming_user.name} has claimed ownership of {claimData.tag_details?.sex} {claimData.tag_details?.breed} {claimData.tag_details?.species}
          </Text>

          <Box pad="medium" background="light-3" round="small" margin={{ bottom: 'medium' }}>
          <h3>Claiming User</h3>
          <p>Name: {claimData.claiming_user.name}</p>
          <p>Phone: {claimData.claiming_user.phone}</p>
          <p>Email: {claimData.claiming_user.email || 'N/A'}</p>
          </Box>
          </>
          )}

  
          {!claimData.tagownerclaim.approvedon && (
            <Box align="center" pad="medium">
              <Text size="large" margin={{ bottom: 'small' }}>
                Approve transfer of ownership to claiming user?
              </Text>
              <Box direction="row" gap="medium" justify="center">
                <Button color="status-ok" onClick={handleApprove} label="Yes" primary />
                <Button color="status-critical" onClick={handleReject} label="No" />
              </Box>
            </Box>
          )}
          {claimData.tagownerclaim.approvedon && !claimData.tagownerclaim.approved && (
            <Box align="center" pad="medium">
              <Text size="large" margin={{ bottom: 'small' }}>
              The claim request was rejected
              </Text>
            </Box>
          )}
          {claimData.tagownerclaim.approved && (
            <Box align="center" pad="medium">
              <Text size="large" margin={{ bottom: 'small' }}>
              The claim request was approved
              </Text>
            </Box>
          )}
        </>
      )}
  
      {showConfirmation && (
        <Layer onEsc={cancelTransfer} onClickOutside={cancelTransfer}>
          <Box pad="medium" gap="small" width="medium">
            <Text>
              Please confirm you want to transfer {claimData.tag_details?.sex} {claimData.tag_details?.breed} {claimData.tag_details?.species} to {claimData.claiming_user.name}
            </Text>
            <Box direction="row" gap="medium" justify="between">
              <Button color="status-ok" onClick={confirmTransfer} label="Confirm" primary />
              <Button color="status-critical" onClick={cancelTransfer} label="Cancel" />
            </Box>
          </Box>
        </Layer>
      )}
    </div>
  );
  
};

export default Claim;
